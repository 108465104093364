// @flow
import { connect } from 'react-redux';
import Dashboard from './Dashboard.component';
import { getAllCampaignActionCreator, allCampaign, generateFileActionCreator, getIsLoading,
    getIsGeneratingLoading, downloadFileCsvActionCreator, downloadFileOcrActionCreator, getIsDownloadingCsvLoading, getIsDownloadingOcrLoading,
    deleteCompaignActionCreator, getIsDeletingCampaignLoading } from '../../modules/compaign';
import { getError } from '../../modules/error';
import { getUserProfile, logoutActionCreator } from '../../modules/authentication';

const mapStateToProps = state => ({
    allCampaign: allCampaign(state),
    isApiLoading: getIsLoading(state),
    isGeneratingLoading: getIsGeneratingLoading(state),
    isDownloadingOcrLoading: getIsDownloadingOcrLoading(state),
    isDownloadingCsvLoading: getIsDownloadingCsvLoading(state),
    handledError: getError(state),
    userProfile: getUserProfile(state),
    isDeletingCampaignApiLoading: getIsDeletingCampaignLoading(state),
});
const mapDispatchToProps = ({
    onGetAllCampaign: getAllCampaignActionCreator,
    onGenerate: generateFileActionCreator,
    onDownloadFileCsv: downloadFileCsvActionCreator,
    onDownloadFileOcr: downloadFileOcrActionCreator,
    onLogout: logoutActionCreator,
    onDeletecampaign: deleteCompaignActionCreator,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
