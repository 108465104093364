import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { USER_POOL_ID, CLIENT_ID, ENV } from '../environment';

const poolData = {
  UserPoolId: USER_POOL_ID, // User Pool ID
  ClientId: CLIENT_ID, // Client App Id
};
export const NEW_PASSWORD_REQUIRED_FLAG = 'NEW_PASSWORD_REQUIRED';

export default class Cognito {
  authenticateUser(loginData: any) {
    const { email, password } = loginData;
    const authenticationData = {
      Username: email,
      Password: password,
    };
    return new Promise((resolve, reject) => {
      const authenticationDetails = new AuthenticationDetails(authenticationData);
      const userPool = this.getCognitoUserPool(poolData);
      const userData = {
        Username: email,
        Pool: userPool,
      };
      const cognitoUser = this.getCognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: result => resolve(result),
        onFailure: err => reject(err),
        newPasswordRequired: (userAttributes, requiredAttributes) => {
          delete userAttributes.email_verified;
          if (loginData.newPassword) {
            return cognitoUser.completeNewPasswordChallenge(loginData.newPassword, requiredAttributes, {
              onSuccess: result => resolve(result),
              onFailure: err => reject(err),
            });
          }
          resolve({ flag: NEW_PASSWORD_REQUIRED_FLAG });
        },
      });
    });
  }

  resendConfirmationCode(email: string) {
    return new Promise((resolve, reject) => {
      const userPool = this.getCognitoUserPool(poolData);
      const userData = {
        Username: email,
        Pool: userPool,
      };
      const cognitoUser = this.getCognitoUser(userData);
      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          reject(err);
          return;
        }
        return resolve(result);
      });
    });
  }

  confirmRegistration(email: string, confirmationCode: number) {
    return new Promise((resolve, reject) => {
      const userPool = this.getCognitoUserPool(poolData);
      const userData = {
        Username: email,
        Pool: userPool,
      };
      const cognitoUser = this.getCognitoUser(userData);
      cognitoUser.confirmRegistration(confirmationCode, true, (err, result) => {
        if (err) {
          reject(err);
          return;
        }
        return resolve(result);
      });
    });
  }

  signOut(email: string) {
    const userPool = this.getCognitoUserPool(poolData);
    const userData = {
      Username: email,
      Pool: userPool,
    };
    const cognitoUser = this.getCognitoUser(userData);
    return cognitoUser.signOut();
  }

  getCognitoUser(userData) {
    return new CognitoUser(userData);
  }

  getCognitoUserPool() {
    return new CognitoUserPool(poolData);
  }
  /* eslint prefer-promise-reject-errors: */

  checkValiditySession() {
    return new Promise((resolve, reject) => {
      var userPool = this.getCognitoUserPool(poolData);
        const cognitoUser = userPool.getCurrentUser();
        if (cognitoUser) {
          cognitoUser.getSession((sessErr, session) => {
            if (sessErr) {
              reject({ code: 'UserSessionExpiredException' });
            } else if (session.isValid()) {
              resolve(session.idToken.jwtToken);
            } else {
              reject({ code: 'UserSessionExpiredException' });
            }
          });
        } else {
          reject({ code: 'UserSessionExpiredException' });
        }
    });
  }

  forgotPassword(email: string) {
    return new Promise((resolve, reject) => {
      const userPool = this.getCognitoUserPool();
      const userData = {
        Username: email,
        Pool: userPool,
      };
      const cognitoUser = this.getCognitoUser(userData);
      cognitoUser.forgotPassword({
        onSuccess: data => resolve(data),
        onFailure: error => reject(error),
      });
    });
  }

  confirmPassword(email: string, code: number, password: string) {
    return new Promise((resolve, reject) => {
      const userPool = this.getCognitoUserPool();
      const userData = {
        Username: email,
        Pool: userPool,
      };
      const cognitoUser = this.getCognitoUser(userData);
      cognitoUser.confirmPassword(code, this.getRealPassword(password), {
        onSuccess: () => resolve(),
        onFailure: error => reject(error),
      });
    });
  }

  getRealPassword(password: string) {
    // if (ENV === 'prod') return `${password}@${password.length}`;
    return password;
  }
}