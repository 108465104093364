// @flow
import { connect } from 'react-redux';
import ResetPassword from './ResetPassword';
import { getIsLoading } from '../../../modules/authentication';
import { loginActionCreator } from '../../../modules/authentication';
import { getError } from '../../../modules/error';

const mapStateToProps = state => ({
  isApiLoading: getIsLoading(state),
  handledError: getError(state)
});
const mapDispatchToProps = ({
  onLogin: loginActionCreator,
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
