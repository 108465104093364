import * as Yup from 'yup';
import translate from './Translation';

export const signinSchema = Yup.object().shape({
    email: Yup.string()
      .email(translate('invalidEmail'))
      .required(translate('requiredEmailField')),
    password: Yup.string()
      .min(8, translate('mdpMinLengtLength'))
      .required(translate('requiredPasswordField')),
  });
  
  export const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, translate('mdpMinLengtLength'))
      .required(translate('requiredPasswordField')),
    newPassword: Yup.string()
      .min(8, translate('mdpMinLengtLength'))
      .required(translate('requiredPasswordField')),
    /*passwordConfirmation: Yup.string()
     .oneOf([Yup.ref('password'), null], translate('matchingPasswordError'))*/
  });

  export const forgetPasswordSchema = Yup.object().shape({
    email: Yup.string()
    .email(translate('invalidEmail'))
    .required(translate('requiredEmailField')),
  });

  export const initPasswordSchema = Yup.object().shape({
    code: Yup
      .string()
      .required(translate('codeRequiredError')),
    password: Yup.string()
      .min(8, translate('mdpMinLengtLength'))
      .required(translate('requiredPasswordField')),
  });

  export const createCompaignStepSchema = Yup.object().shape({
    compaignName: Yup
      .string()
      .required(translate('requiredCompaignName')),
    duration: Yup
    .number(translate('durationMustBeNumber'))
    .required(translate('durationRequiredError'))
    .integer(translate('durationMustBeInteger'))
    .min(1)
    .max(100)
  });