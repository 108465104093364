import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import appStyle from '../../../appStyle';
import translate from '../../../services/Translation';
import { resetPasswordSchema } from '../../../services/FormValidation';


const styles = {
    container: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: appStyle.colors.pageBackground,
    },
    leftPanel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: appStyle.margins.l,
        paddingRight: appStyle.margins.l,
        paddingTop: appStyle.paddings.m,
        width: '15%',
        height: '100vh',
        backgroundColor: appStyle.colors.primary,
    },
     headerBar: {
        height: 63,
        backgroundColor: appStyle.colors.white,
        boxShadow: ' 0 4px 5px -2px gray',
     },
     formContainer: {
         display: 'flex',
         flexDirection: 'column',
         alignItems: 'center',
         justifyContent: 'center',
         height: '100vh',
     },
     formView: {
         display: 'flex',
         flexDirection: 'column',
         width: '50%',
         height: '50vh',
         paddingTop: appStyle.paddings.xxl,
         paddingLeft: appStyle.paddings.xl,
         paddingRight: appStyle.paddings.xl,
         borderRadius: appStyle.radiuses.xl,
         boxShadow: ' 0px 0px 5px -2px gray',
     },
     form: {
         display: 'flex',
         flexDirection: 'column',
     },
     fieldStyle: {
         marginBottom: appStyle.margins.l,
         minHeight: 45,
         border: 'solid',
         borderWidth: 2,
         borderColor: appStyle.colors.warmGrey,
         borderRadius: appStyle.radiuses.m,
         padding: appStyle.paddings.m,
         fontSize: appStyle.font.sizes.small,
     },
     fieldError: {
        textAlign: 'center',
        color: appStyle.colors.red,
     },
     button: {
        backgroundColor: appStyle.colors.primary,
        height: 36,
        width: 136,
        borderRadius: 0,
        alignSelf: 'flex-end',
        color: appStyle.colors.white,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
     },
     image: {
         maxHeight: 96,
         minWidth: 172,
         maxWidth: '12vw',
     },
}

type PropsType = {
    isApiLoading: Boolean,
    onLogin: (authenticationData: any) => void,
    handledError: String,
};
class ResetPassword extends React.Component {
    props: PropsType;
    
    render() {
        // console.log(this.props.location.state);
        return (
            <Box style={styles.container}>
            <Box style={styles.leftPanel}>
            <img src="assets/images/ressources/appLogo.png" style={styles.image}/>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'column', width: '80%' }}>
              <Box style={styles.headerBar}/>
              <Box style={styles.formContainer}>
                  <Box style={styles.formView}>
                  <Box>
                      <h1>{translate('changePasswordFormHeader')}</h1>
                      <Formik
                          initialValues={{
                          password: '',
                          newPassword: '',
                          }}
                          validationSchema={resetPasswordSchema}
                          onSubmit={values => {
                          const email = this.props.location.state ? this.props.location.state.email : null;
                          // console.log(values);
                          this.props.onLogin({
                              email,
                              password: values.password,
                              newPassword: values.newPassword,
                          });
                          }}
                      >
                          {({ errors, touched }) => (
                          <Form style={styles.form}>
                             <Field style={styles.fieldStyle} name="password" type="password" placeholder={translate(('oldPasswordPlaceHolder'))}/>
                              {errors.password && touched.password ? (
                              <div style={styles.fieldError}>{errors.password}</div>
                              ) : null}
                              <Field style={styles.fieldStyle} name="newPassword" type="password" placeholder={translate(('newPasswordPlaceHolder'))}/>
                              {errors.newPassword && touched.newPassword ? (
                              <div style={styles.fieldError}>{errors.newPassword}</div>
                              ) : null}
                                {this.props.handledError ? (
                                <div style={styles.fieldError}>{this.props.handledError}</div>
                                ) : null}
                              <button style={styles.button} type="submit" disabled={this.props.isApiLoading}>
                                {this.props.isApiLoading && <CircularProgress size={24} style={{ color: appStyle.colors.white, marginRight: appStyle.margins.sm }} />}
                                {translate('submit')}
                              </button>
                          </Form>
                          )}
                      </Formik>
                  </Box>
                  </Box>
              </Box>
            </Box>
          </Box>
        )
    }
}

export default ResetPassword; 