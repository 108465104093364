import React from 'react';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik, Form, Field } from 'formik';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import CloudUpload from '@material-ui/icons/CloudUpload';
import WarningIcon from '@material-ui/icons/Warning';
import ArrowBack from '@material-ui/icons/ArrowBack';
import BlankIcon from '@material-ui/icons/Warning';
import appStyle from '../../../appStyle';
import translate from '../../../services/Translation';
import { createCompaignStepSchema } from '../../../services/FormValidation';
import { HeaderBar } from '../components';
import { HEADER_MESSAGE_SIZE } from '../../../environment';
import { readString } from 'react-papaparse';

const CSV_FILE_NB_COLS = 8;
const SMS_MESSAGE_MAXE_SIZE = 160;
const SMS_MESSAGE_MAXE_SIZE_UCS2 = 70;
const gsmCodePoints = new Set([
  0x000a, 0x000c, 0x000d, 
  0x0020, 0x0021, 0x0022, 0x0023, 0x0024, 0x0025, 0x0026, 0x0027, 0x0028, 0x0029, 0x002a, 0x002b, 0x002c, 0x002d, 0x002e, 0x002f,
  0x0030, 0x0031, 0x0032, 0x0033, 0x0034, 0x0035, 0x0036, 0x0037, 0x0038, 0x0039, 0x003a, 0x003b, 0x003c, 0x003d, 0x003e, 0x003f,
  0x0040, 0x0041, 0x0042, 0x0043, 0x0044, 0x0045, 0x0046, 0x0047, 0x0048, 0x0049, 0x004a, 0x004b, 0x004c, 0x004d,
  0x004e, 0x004f,
  0x0050, 0x0051, 0x0052, 0x0053, 0x0054, 0x0055, 0x0056, 0x0057, 0x0058, 0x0059,  0x005a, 0x005b, 0x005c, 0x005d, 0x005e, 0x005f, 
  0x0061, 0x0062, 0x0063, 0x0064, 0x0065, 0x0066, 0x0067, 0x0068, 0x0069, 0x006a, 0x006b, 0x006c, 0x006d, 0x006e, 0x006f, 
  0x0070, 0x0071, 0x0072, 0x0073, 0x0074, 0x0075, 0x0076, 0x0077, 0x0078, 0x0079, 0x007a, 0x007b, 0x007c, 0x007d, 0x007e,
  0x00a1, 0x00a3, 0x00a4, 0x00a5, 0x00a7,
  0x00bf,
  0x00c4, 0x00c5, 0x00c6, 0x00c7, 0x00c9,
  0x00d1, 0x00d6, 0x00d8, 0x00dc, 0x00df,
  0x00e0, 0x00e4, 0x00e5, 0x00e6, 0x00e8, 0x00e9, 0x00ec,
  0x00f1, 0x00f2, 0x00f6, 0x00f8, 0x00f9, 0x00fc,
  0x0393, 0x0394, 0x0398, 0x039b, 0x039e, 0x03a0, 0x03a3, 0x03a6, 0x03a8, 0x03a9,
  0x20ac,
]);
const gsmregexp = new RegExp("^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$");

const styles = {
    container: {
        width: '100%',
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: appStyle.colors.pageBackground,
    },
    leftPanel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: appStyle.margins.l,
        paddingRight: appStyle.margins.l,
        paddingTop: appStyle.paddings.m,
        width: '15%',
        height: '100vh',
        backgroundColor: appStyle.colors.primary,
    },
     headerBar: {
        height: 63,
        backgroundColor: appStyle.colors.white,
        boxShadow: ' 0 4px 5px -2px gray',
     },
     image: {
         height: 48,
         width: 172,
     },
      infoContainer: {
          paddingLeft: appStyle.paddings.eightxl,
      },
      stepLabel:{
          fontSize: appStyle.font.sizes.xs,
      }, 
      continueButton: {
          backgroundColor: appStyle.colors.primary,
          borderRadius: appStyle.radiuses.m,
          height: 36,
          width: 90,
          color: appStyle.colors.secondary,
          fontSize: appStyle.font.sizes.xs,
          border: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
      },
      cancelButton: {
        backgroundColor: 'transparent',
        borderRadius: appStyle.radiuses.m,
        height: 36,
        width: 90,
        border: 0,
        fontSize: appStyle.font.sizes.xs,
        cursor: 'pointer',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: appStyle.margins.m,
        marginBottom: appStyle.margins.m,
    },
    fieldStyle: {
        marginBottom: appStyle.margins.l,
        minHeight: 45,
        width: 400,
        border: 'solid',
        borderWidth: 2,
        borderColor: appStyle.colors.warmGrey,
        borderRadius: appStyle.radiuses.m,
        padding: appStyle.paddings.m,
        fontSize: appStyle.font.sizes.small,
    },
    messageFieldStyle: {
        minHeight: 45,
        width: '100%',
        minWidth: 400,
        border: 'solid',
        borderWidth: 2,
        borderColor: appStyle.colors.warmGrey,
        borderRadius: appStyle.radiuses.m,
        padding: appStyle.paddings.m,
        fontSize: appStyle.font.sizes.small,
        alignSelf: 'flex-end'
    },
    fieldError: {
       textAlign: 'right',
       color: appStyle.colors.red,
    },
    uploadButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: 0,
        outline: 'none',
        marginTop: appStyle.margins.m,
        marginBottom: appStyle.margins.m,
        cursor: 'pointer',
    },
    fieldError: {
        textAlign: 'center',
        color: appStyle.colors.red,
     },
    fieldWarning: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'left',
      border: 0,
      outline: 'none',
      textAlign: 'left',
      marginTop: appStyle.margins.m,
      marginBottom: appStyle.margins.m,
      color: appStyle.colors.orange,
      fontSize: appStyle.font.sizes.xs,
    },
    fieldWarningFirst: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'left',
      border: 0,
      outline: 'none',
      textAlign: 'left',
      marginTop: appStyle.margins.m,
      marginBottom: 0,
      color: appStyle.colors.orange,
      fontSize: appStyle.font.sizes.small,
    },
    fieldWarningSecond: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'left',
      border: 0,
      outline: 'none',
      textAlign: 'left',
      marginTop: 0,
      marginBottom: appStyle.margins.m,
      color: appStyle.colors.orange,
      fontSize: appStyle.font.sizes.small,
    },
    fieldErrorLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'left',
      border: 0,
      outline: 'none',
      textAlign: 'left',
      marginTop: appStyle.margins.m,
      marginBottom: appStyle.margins.m,
      color: appStyle.colors.red,
      fontSize: appStyle.font.sizes.xs,
    },
    previewContainer: {
        heigth: 45,
        width: 400,
        borderWidth: 1,
        borderColor: appStyle.colors.warmGrey, 
     },
     arrowBack: {
         fontSize: appStyle.font.sizes.xxl,
         paddingLeft: appStyle.paddings.xl,
         paddingTop: appStyle.paddings.xxxl,
         cursor: 'pointer',
     },
}
type PropsType = {
    handledError: String,
    isApiLoading: Boolean,
    onCreateCompaign: (compaignData: any) => void,
    onUploadCompaignAgain: (compaignFile: any, fileUrl: String) => void,
    createdCompaign: any,
    errorText: String,
};

type StateType = {
    prevActiveStep: Number,
    compaignFile: any,
    compaignData: any,
    userProfile: any,
    onLogout: (email: String) => void,
    profileData: any,
    onGetProfile: () => void,
};

class NewCompaign extends React.Component {
    constructor(props) {
        super(props);
        const defaultMessageLength = props.profileData ? props.profileData.brand.urlSize + HEADER_MESSAGE_SIZE + props.profileData.brand.messageConfiguration.content.length: HEADER_MESSAGE_SIZE;
        // console.log(defaultMessageLength);
        this.state = {
            prevActiveStep: 0,
            compaignFile: null,
            compaignData: null,
            compaignFileMissedError: null,
            messagePreview: props.profileData ? props.profileData.brand.messageConfiguration.content : null,
            messageLength: props.profileData ? props.profileData.brand.urlSize + HEADER_MESSAGE_SIZE + props.profileData.brand.messageConfiguration.content.length: HEADER_MESSAGE_SIZE,
            smsCostCount: Math.ceil(defaultMessageLength / SMS_MESSAGE_MAXE_SIZE),
            campaignFileStats: null,
            fileInputKey: '',
            gsmCompliant: true,
            invalidCharAt: -1,
            invalidCharMsg: '',
            messagePreviewDisplayed: props.profileData ? props.profileData.brand.messageConfiguration.content : null,
        };
    }
    componentWillMount() {
      if (!this.props.profileData) return this.props.onGetProfile();
    }
    componentDidUpdate(prevProps) {
      if ((prevProps.profileData !== this.props.profileData) && this.props.profileData) {
        const defaultMessageLength = this.props.profileData ? this.props.profileData.brand.urlSize + HEADER_MESSAGE_SIZE + this.props.profileData.brand.messageConfiguration.content.length: HEADER_MESSAGE_SIZE;
        return this.setState({ 
          messagePreview: this.props.profileData.brand.messageConfiguration.content,
          messageLength: this.props.profileData ? this.props.profileData.brand.urlSize + HEADER_MESSAGE_SIZE + this.props.profileData.brand.messageConfiguration.content.length: HEADER_MESSAGE_SIZE,
          smsCostCount: Math.ceil(defaultMessageLength / SMS_MESSAGE_MAXE_SIZE),
          messagePreviewDisplayed: this.props.profileData.brand.messageConfiguration.content,
         });
      }
        
    }
    handleNext() {
        this.setState({ prevActiveStep: this.state.prevActiveStep + 1, compaignFileMissedError: null  })
      }
    
    handleBack() {
        this.setState({ prevActiveStep: this.state.prevActiveStep - 1, compaignFile: null, compaignData: null })
      }
    
    handleReset() {
        this.setState({ prevActiveStep: 0 })
      }

    /**
     * Check the csv file format
     * @param {*} results 
     */
    checkCsvFile(results) {
      // console.log(results);
      if (results.data && (!results.errors || results.errors.length <= 0)) {
        this.setState({ campaignFileStats: { nbLines: results.data ? results.data.length : '0'} });
        if(results.data.length > 0) {
          for (let i = 0; i < results.data.length; i += 1) {
            const line = results.data[i];
            if (i !== results.data.length - 1) {
              if (! Array.isArray(line) || line.length !== CSV_FILE_NB_COLS) {
                this.setState({ campaignFileStats: { error: `${translate('compaignFileInvalidLine')} ${i+1} !` } });
                return false;
              }
            }
          }
        }
        return true;
      } else {
        this.setState({ campaignFileStats: { error: translate('compaignFileInvalidFile') } });
        return false;
      }
    }

    handleChange(e)
    {
        e.persist()
        const selectorFiles = e.target.files;
        const self = this;
        self.setState({ compaignFile: null });
        var reader = new FileReader();
        reader.onload = function() {
          let blobData = new Blob([new Uint8Array(this.result)], {type: 'text/csv'});
          self.setState({ compaignFile: blobData });
          // Check CSV File
          var fileReader = new FileReader();
          fileReader.onload = function(fileLoadedEvent){
              var textFromFileLoaded = fileLoadedEvent.target.result;
              const conf = {
                delimiter: ';',
                header: false,
              };
              const results = readString(textFromFileLoaded, conf);
              // console.log(results);
              if (self.checkCsvFile(results) === false) {
                e.target.value = null;
                e.target.files = null;
                self.setState({ fileInputKey: Date.now(), compaignFile: null });
              }
          };
          fileReader.readAsText(selectorFiles[0], "UTF-8");
          //
        }
        reader.readAsArrayBuffer(selectorFiles[0]);
    }

    createCompaign() {
        const { compaignData, compaignFile, messagePreview } = this.state;
        if (this.props.createdCompaign) {
            const fileUploadUrl = this.props.createdCompaign.uploadFile.fileUrl;
            this.props.onUploadCompaignAgain(compaignFile, fileUploadUrl);
        } else {
            compaignData.customMessageContent = messagePreview;
            this.props.onCreateCompaign(compaignData, compaignFile);
        }
    }
    checkFileStep() {
        if (!this.state.compaignFile) return this.setState({ compaignFileMissedError: 'compaignFileMissedError' })
        this.handleNext();
    }
    onMenuClick(email: String) {
        this.props.onLogout(email);
    }
    handleMessageChange = (e) => {
        const { profileData } = this.props;
        const staticMessageLength = profileData ? profileData.brand.urlSize + HEADER_MESSAGE_SIZE : HEADER_MESSAGE_SIZE;
        this.setState({ messageLength: e.target.value.length + staticMessageLength,
                        messagePreview:  e.target.value,
                         }, () => this.smsCostCalculation(this.state.messageLength, this.state.messagePreview));
        
    }
    smsCostCalculation(messageLength: Number, message: String) {
        // Check GSM Format compliance
        let index = 0;
        let compliant = true;
        let warningMessage = ''; 
        let messageToDisplay = message;
        for (const s of message) {
          const codePoint = s.codePointAt(0);
          if (codePoint && !gsmCodePoints.has(codePoint)) {
            compliant = false;
            messageToDisplay = messageToDisplay.replaceAll(s, `<span style="background-color: rgb(245, 166, 35);">${s}</span>`)
          }
          index += 1;
        }
        // console.log(compliant);
        if (compliant === true) {
          return this.setState({ messagePreviewDisplayed: message, invalidCharMsg: '', gsmCompliant: true, invalidCharAt: -1, smsCostCount: Math.ceil(messageLength / SMS_MESSAGE_MAXE_SIZE) });
        }
        // TODO: Comment mettre en rouge le caractère qui pose problème ??
        return this.setState({ messagePreviewDisplayed: messageToDisplay, invalidCharMsg: warningMessage, gsmCompliant: false, invalidCharAt: (index + 1), smsCostCount: Math.ceil(messageLength / SMS_MESSAGE_MAXE_SIZE_UCS2) });
        // return this.setState({ gsmCompliant: false, invalidCharAt: (index + 1) }); ;
        // return this.setState({ gsmCompliant: true, invalidCharAt: -1 }); ;
       
    }
    props: PropsType;

    render() {
        const submitButtonTitle = this.props.errorText ? 'tryAgainButtonTitle' : 'finishStepButtonTitle';
        const { userProfile, profileData } = this.props;
        const email = userProfile && userProfile.idToken && userProfile.idToken.payload ? userProfile.idToken.payload.email: '';
        const headerMessage = profileData ? profileData.brand.messageConfiguration.header : '';
        const contentMessage = profileData ? profileData.brand.messageConfiguration.content : '';
        const staticMessageLength = profileData ? profileData.brand.urlSize + HEADER_MESSAGE_SIZE : HEADER_MESSAGE_SIZE;
        return (
            <Box style={styles.container}>
              <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <HeaderBar email={email} onPress={() => this.onMenuClick(email)}/>
                <ArrowBack style={styles.arrowBack} onClick={() => this.props.history.goBack()}/>
                <Box style={styles.infoContainer}>
                  <p style={{ fontSize: appStyle.font.sizes.l }}>{translate('createCompaignTitle')}</p>
                  <Stepper activeStep={this.state.prevActiveStep} orientation="vertical">
                    <Step key={translate('createCompaignTitle')}>
                        <StepLabel style={styles.stepLabel}>{translate('createCompaignTitle')}</StepLabel>
                        <StepContent>
                        <div>
                            <Box>
                        <Formik
                            initialValues={{
                            compaignName: '',
                            duration: '',
                            }}
                            validationSchema={createCompaignStepSchema}
                            onSubmit={values => {
                            // same shape as initial values
                            const compaign = {
                                name: values.compaignName,
                                duration: values.duration,
                                delay: 0,
                            };
                            this.setState({ compaignData: compaign })
                            }}
                        >
                            {({ errors, touched }) => (
                            <Form style={styles.form}>
                                <Field style={styles.fieldStyle} name="compaignName" placeholder={translate('compaignNamePlaceHolder')}/>
                                {errors.compaignName && touched.compaignName ? (
                                <div style={styles.fieldError}>{errors.compaignName}</div>
                                ) : null}
                                <Field style={styles.fieldStyle} type="number" name="duration" placeholder={translate('durationPlaceHolder')}/>
                                {errors.duration && touched.duration ? (
                                <div style={styles.fieldError}>{errors.duration}</div>
                                ) : null}
                                {this.props.handledError ? (
                                <div style={styles.fieldError}>{this.props.handledError}</div>
                                ) : null}
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <button
                                    disabled={this.state.prevActiveStep === 0}
                                    onClick={() => this.handleBack()}
                                    style={styles.cancelButton}
                                >
                                    {translate('cancelButtonTitle')}
                                </button>
                                <button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => (Object.entries(errors).length === 0 && Object.entries(touched).length !== 0) && this.handleNext()}
                                    style={styles.continueButton}
                                >
                                    {translate('continueButtonTitle')}
                                </button>
                              </div>         
                            </Form>
                            )}
                        </Formik>
                    </Box>
                        </div>
                        </StepContent>
                    </Step>
                    <Step key={translate('customerLoadFileStepTitle')}>
                        <StepLabel style={styles.stepLabel}>{translate('customerLoadFileStepTitle')}</StepLabel>
                        <StepContent>
                            <StepLabel style={styles.stepLabel}>{translate('selectFileTitle')}</StepLabel>
                            <div style={styles.uploadButton}>
                              <CloudUpload style={{ marginRight: appStyle.margins.m }}/>
                              <input accept=".csv" type="file" id="file_to_upload" name="file" onChange={ (e) => this.handleChange(e) } key={this.state.fileInputKey || '' }/>
                            </div>
                            {this.state && this.state.campaignFileStats && this.state.campaignFileStats.nbLines ? (                                
                                  <div style={styles.fieldWarning}>
                                  <WarningIcon style={{ marginRight: appStyle.margins.m }}/> {translate('compaignFileNbLines')} {this.state.campaignFileStats.nbLines}
                                </div>
                                ) : null}
                            {this.state && this.state.campaignFileStats && this.state.campaignFileStats.error ? (                                
                                  <div style={styles.fieldErrorLabel}>
                                  <WarningIcon style={{ marginRight: appStyle.margins.m }}/> {this.state.campaignFileStats.error}
                                </div>
                                ) : null}
                            <div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <button
                                    disabled={this.state.prevActiveStep === 0}
                                    onClick={() => this.handleBack()}
                                    style={styles.cancelButton}
                                >
                                    {translate('cancelButtonTitle')}
                                </button>
                                <button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.checkFileStep()}
                                    style={styles.continueButton}
                                >
                                    {this.props.isApiLoading && <CircularProgress size={24} style={{ color: appStyle.colors.white, marginRight: appStyle.margins.sm }} />}
                                    {translate('continueButtonTitle')}
                                </button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                    <Step key={translate('checkSmsOrEmailtextStepTitle')}>
                        <StepLabel style={styles.stepLabel}>{translate('checkSmsOrEmailtextStepTitle')}</StepLabel>
                        <StepContent>
                             <StepLabel style={styles.stepLabel}>{translate('updateMessageTitle')}</StepLabel>
                            <div style={{ flexDirection: 'row', display: 'flex', width: '80%', justifyContent: 'space-between' }}>
                            <div style={styles.previewContainer}>
                                    <p style={{ fontSize: appStyle.font.sizes.small, fontWeight: 'bold' }}>{translate('preview')}</p>
                                    <p>{`« ${headerMessage}`}</p>
                                    <div style={ { whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: this.state.messagePreviewDisplayed }} />
                                    <p>{translate('userLink')} »</p>
                                </div>
                                <div>
                                <p>{`« ${headerMessage}`}</p>
                                <textarea type="text" maxLength={640 - staticMessageLength} style={styles.messageFieldStyle} defaultValue={contentMessage} onChange={(e) => this.handleMessageChange(e)}/>
                                <p>{translate('userLink')} »</p>
                                {this.state && this.state.invalidCharAt && this.state.invalidCharAt > -1 ? (                                
                                  <div>
                                    <div style={styles.fieldWarningFirst}>
                                      <WarningIcon style={{ marginRight: appStyle.margins.m }}/> {translate('gsmNonCompliant', {charIndex: this.state.invalidCharAt})}
                                    </div>
                                    <div style={styles.fieldWarningSecond}>
                                      <BlankIcon style={{ marginRight: appStyle.margins.m, fill: 'white' }}/> {translate('gsmNonCompliant2', {charIndex: this.state.invalidCharAt})}
                                    </div>
                                  </div>
                                  
                                ) : null}
                                <p 
                                    style={{ 
                                        textAlign: 'end',
                                        color: appStyle.colors.warmGrey, 
                                        fontSize: appStyle.font.sizes.xxs,
                                        fontStyle: 'italic',
                                     }}>
                                    {translate('costAbout')}{`${this.state.smsCostCount} SMS`}
                                </p>
                                </div>
                            </div>
                            <div>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <button
                                    disabled={this.state.prevActiveStep === 0}
                                    onClick={() => this.handleBack()}
                                    style={styles.cancelButton}
                                >
                                    {translate('cancelButtonTitle')}
                                </button>
                                <button
                                    type="button"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => this.createCompaign()}
                                    style={styles.continueButton}
                                >
                                    {this.props.isApiLoading ? <CircularProgress size={24} style={{ color: appStyle.colors.white, marginRight: appStyle.margins.sm }} />
                                    : translate(submitButtonTitle)}
                                </button>
                                </div>
                            </div>
                        </StepContent>
                    </Step>
                  </Stepper>
                    {this.props.errorText ? (
                        <div style={styles.fieldError}>{this.props.errorText}</div>
                    ) : null}
                    {this.state.compaignFileMissedError ? (
                        <div style={styles.fieldError}>{translate(this.state.compaignFileMissedError)}</div>
                    ) : null}
                </Box>
              </Box>
            </Box>
        )
    }
}

export default NewCompaign; 