import React from 'react';
import { Box } from '@material-ui/core';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import appStyle from '../../../../appStyle';
import translate from '../../../../services/Translation';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: appStyle.colors.pageBackground,
        padding: appStyle.paddings.m,
        marginBottom: appStyle.margins.xxsm,
        minHeight: 100,
        cursor: 'pointer',
    },
    verticalView: {
        width: 7,
        minHeight: 100,
        backgroundColor: 'transparent',
        borderRadius: appStyle.radiuses.xl,
        marginRight: appStyle.margins.xxxl,
    },
    campaignName: {
        fontWeight: 'bold',
    },
    circleProgress: {
        minHeight: 50,
        width: 50,
        marginLeft: 'auto'
    },
}
type PropsType = {
    onPress: () => void,
    campaignName: String,
    duration: Number,
    isSelectedItem: Boolean,
    fillRate: Number,
};


class CampaignItem extends React.Component {
    constructor() {
        super();
    }
    props: PropsType;

    render() {
        let verticalViewStyle = styles.verticalView;
        if (this.props.isSelectedItem) verticalViewStyle = { ...verticalViewStyle, backgroundColor: appStyle.colors.primary };
        return (
            <Box style={styles.container} onClick={() => this.props.onPress()}>
                <Box style={verticalViewStyle}/>
                <Box>
                    <p style={styles.campaignName}>{this.props.campaignName}</p>
                    <p>{`${translate('durationTitle')} : ${this.props.duration} ${translate('dayUnit')}`}</p>
                </Box>
                    <CircularProgressbar
                        styles={{
                            root: styles.circleProgress,
                        }}
                        value={this.props.fillRate} text={`${Math.round(this.props.fillRate)}%`}
                    />
            </Box>
        )
    }
}

export default CampaignItem; 