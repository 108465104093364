import React from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Speed from '@material-ui/icons/Speed';
import Delete from '@material-ui/icons/Delete';
import CircularProgress from '@material-ui/core/CircularProgress';
import Warning from '@material-ui/icons/Warning';
import appStyle from '../../../../appStyle';
import translate from '../../../../services/Translation';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: appStyle.font.sizes.l,
        fontWeight: 'bold',
    },
    subtitle: {
        fontSize: appStyle.font.sizes.small,
        fontWeight: 'bold',
        marginTop: appStyle.margins.xl,
    },
    dateContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: appStyle.margins.m,
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: appStyle.margins.m,
        paddingRight: appStyle.paddings.eightxl,
    },
    icon: {
        height: 18,
        width: 18,
        borderRadius: appStyle.radiuses.m,
        backgroundColor: appStyle.colors.primary,
        color: appStyle.colors.secondary,
        padding: appStyle.paddings.sm,
    },
    performIcon: {
        height: 18,
        width: 18,
        borderRadius: appStyle.radiuses.m,
        backgroundColor: appStyle.colors.greens.green_1,
        color: appStyle.colors.secondary,
        padding: appStyle.paddings.sm,
    },
    separator: {
        height: 1,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: appStyle.colors.warmGrey,
        marginRight: appStyle.paddings.eightxl,
        marginTop: appStyle.margins.l,
        marginBottom: appStyle.margins.l,
    },
    warningIcon: {
        color: appStyle.colors.campaignStatus.inerror,
        marginRight: appStyle.margins.xxl,
    },
    warningContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center',
    },
    deleteButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center',
        marginTop: appStyle.margins.xl,
        cursor: 'pointer',
    },
}
type PropsType = {
    campaign: any,
    isApiLoading: Boolean,
    onPress: () => void,
};


class CampaignInErrorStatus extends React.Component {
    constructor() {
        super();
    }
    props: PropsType;

    render() {
        const { campaign } = this.props;
        const compaignName = campaign ? campaign.name : '-';
        const fromDate = campaign && campaign.plannedProcessDate ? moment(campaign.plannedProcessDate).format('DD/MM/YYYY') : '-';
        const toDate = campaign && campaign.plannedEndDate ? moment(campaign.plannedEndDate).format('DD/MM/YYYY') : '-';
        const duration = campaign && campaign.duration ? campaign.duration : '-';
        const meterNumber = campaign && campaign.analytics ? campaign.analytics.nbClients : '-';;
        const campaignStatusError = (campaign) ? campaign.stateDesc : null;
        return (
            <Box style={styles.container}>
                <p style={styles.title}>{compaignName}</p>
                <p style={styles.subtitle}>{translate('campaignInformationTitle')}</p>
                <Box style={styles.infoContainer}>
                    <CalendarToday style={styles.icon}/>
                    <p style={{ marginLeft: appStyle.margins.m, color: appStyle.colors.warmGrey }}>{translate('fromDate')}</p>
                    <p style={{ marginLeft: 'auto' }}>{fromDate}</p>
                    <p style={{ marginLeft: 'auto', color: appStyle.colors.warmGrey }}>{translate('toDate')}</p>
                    <p style={{ marginLeft: 'auto' }}>{toDate}</p>
                    <p style={{ marginLeft: 'auto', color: appStyle.colors.warmGrey }}>{translate('durationTitle')}</p>
                    <p style={{ marginLeft: 'auto' }}>{duration}</p>
                </Box>
                <Box style={styles.infoContainer}>
                    <Speed style={styles.icon}/>
                    <p style={{ marginLeft: appStyle.margins.m, color: appStyle.colors.warmGrey }}>{translate('meterNumberTitle')}</p>
                    <p style={{ marginLeft: 'auto' }}>{meterNumber}</p>
                </Box>
                <Box style={styles.separator}/>
                <p style={styles.subtitle}>{translate('statusInfoTitle')}</p>
                {campaignStatusError ?
                    <Box style={styles.warningContainer}>
                        <Warning style={styles.warningIcon}/>
                        <p 
                            style={{ 
                                color: appStyle.colors.warmGrey,
                                fontSize: appStyle.font.sizes.xs,
                                textAlign: 'center',
                                fontStyle: 'italic',
                                marginTop: appStyle.margins.sm,
                            }}
                        >
                            {`${translate(campaignStatusError)}`}
                        </p>
                    </Box>
                    :
                    <p 
                    style={{ 
                        color: appStyle.colors.warmGrey,
                        fontSize: appStyle.font.sizes.xs,
                        textAlign: 'center',
                        fontStyle: 'italic',
                        marginTop: appStyle.margins.sm,
                     }}
                >
                    {translate('defaultCampaignCreationError')}
                </p> 
                }
                <Box style={styles.deleteButtonContainer} onClick={this.props.onPress}>
                    {this.props.isApiLoading &&
                        <CircularProgress size={24} style={{ color: appStyle.colors.primary, marginRight: appStyle.margins.sm }}/>}
                    <Delete />
                    <p style={{
                        fontSize: appStyle.font.sizes.small,
                        fontWeight: 'bold',
                        marginLeft: appStyle.margins.m,
                    }}>
                        {translate('deleteCampaignButtontitle')}
                    </p>
                </Box>
            </Box>
        )
    }
}

export default CampaignInErrorStatus; 