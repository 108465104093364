// @flow
import { connect } from 'react-redux';
import InitializePassword from './InitializePassword.component';
import { getIsLoading, initPasswordActionCreator } from '../../../modules/authentication';
import { getError } from '../../../modules/error';

const mapStateToProps = state => ({
    handledError: getError(state),
    isApiLoading: getIsLoading(state),
});
const mapDispatchToProps = ({
    onInitPassword: initPasswordActionCreator,
});

export default connect(mapStateToProps, mapDispatchToProps)(InitializePassword);
