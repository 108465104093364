module.exports = {
  ENV: 'prod',
  APP_NAME: 'epo',
  END_POINT: 'https://ssm.services.apihf.com',
  API_KEY: 'WWBdKSCyRO6SnKwC88E8R5dRt0MwiGQprJDfKKD5',
  BRAND_KEY: '20b4d495-ba78-4050-b208-f5205f18f96f',
  CLIENT_ID: '2hcnhauq2qob6f67q6ikcb7of1',
  USER_POOL_ID: 'eu-west-1_HIn9p5IYR',
  HEADER_MESSAGE_SIZE: 41,
  AGENTS_GROUP: 'EPO_AGENTS',
}