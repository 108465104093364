
// @flow

import { call, put } from 'redux-saga/effects';
import * as Api from '../services/Api';
import { API_KEY } from '../environment';
import { actionTypes as loginActionTypes } from './authentication';

export function* setCallAuthorizationToken(apiCall: any): SagaType {
  const sessionRefreshedToken = yield Api.getSessionToken().then(token => token);
  yield put({ type: loginActionTypes.SET_SESSION_ACCESS_TOKEN_ACTION, sessionToken: sessionRefreshedToken });
  const response = yield call(() => Api.addAuthorizationHeader(apiCall, sessionRefreshedToken));
  return response.body;
}
export function authenticatedCall(apiCall: any): SagaType {
  return call(setCallAuthorizationToken, apiCall);
}
