import { actionTypes as authenticationActionTypes } from '../authentication';

// @flow

export const actionTypes = {
  CREATE_COMPAIGN_ACTION: {
    REQUEST: 'CREATE_COMPAIGN_ACTION_REQUEST',
    SUCCESS: 'CREATE_COMPAIGN_ACTION_SUCCESS',
    API_LOADING_START: 'CREATE_COMPAIGN_ACTION_API_LOADING_START',
    API_LOADING_STOP: 'CREATE_COMPAIGN_ACTION_API_LOADING_STOP',
  },
  UPLOAD_FILE_ACTION: {
    REQUEST: 'UPLOAD_FILE_ACTION_REQUEST',
    SUCCESS: 'UPLOAD_FILE_ACTION_SUCCESS',
    API_LOADING_START: 'UPLOAD_FILE_ACTION_API_LOADING_START',
    API_LOADING_STOP: 'UPLOAD_FILE_ACTION_API_LOADING_STOP',
  },
  GET_ALL_CAMPAIGN_ACTION: {
    REQUEST: 'GET_ALL_CAMPAIGN_ACTION_REQUEST',
    SUCCESS: 'GET_ALL_CAMPAIGN_ACTION_SUCCESS',
    API_LOADING_START: 'GET_ALL_CAMPAIGN_ACTION_API_LOADING_START',
    API_LOADING_STOP: 'GET_ALL_CAMPAIGN_ACTION_API_LOADING_STOP',
  },
  GENERATE_FILE_ACTION: {
    REQUEST: 'GENERATE_FILE_ACTION_REQUEST',
    SUCCESS: 'GENERATE_FILE_ACTION_SUCCESS',
    API_LOADING_START: 'GENERATE_FILE_ACTION_API_LOADING_START',
    API_LOADING_STOP: 'GENERATE_FILE_ACTION_API_LOADING_STOP',
  },
  DOWNLOAD_FILE_CSV_ACTION: {
    REQUEST: 'DOWNLOAD_FILE_CSV_ACTION_REQUEST',
    SUCCESS: 'DOWNLOAD_FILE_CSV_ACTION_SUCCESS',
    API_LOADING_START: 'DOWNLOAD_FILE_CSV_ACTION_API_LOADING_START',
    API_LOADING_STOP: 'DOWNLOAD_FILE_CSV_ACTION_API_LOADING_STOP',
  },
  DOWNLOAD_FILE_OCR_ACTION: {
    REQUEST: 'DOWNLOAD_FILE_OCR_ACTION_REQUEST',
    SUCCESS: 'DOWNLOAD_FILE_OCR_ACTION_SUCCESS',
    API_LOADING_START: 'DOWNLOAD_FILE_OCR_ACTION_API_LOADING_START',
    API_LOADING_STOP: 'DOWNLOAD_FILE_OCR_ACTION_API_LOADING_STOP',
  },
  DELETE_CAMPAIGN_ACTION: {
    REQUEST: 'DELETE_CAMPAIGN_ACTION_REQUEST',
    SUCCESS: 'DELETE_CAMPAIGN_ACTION_SUCCESS',
    API_LOADING_START: 'DELETE_CAMPAIGN_ACTION_API_LOADING_START',
    API_LOADING_STOP: 'DELETE_CAMPAIGN_ACTION_API_LOADING_STOP',
  },
};

const initialState = {
  isApiLoading: false,
  createdCompaign: null,
  allCampaign: null,
  isGeneratingFileApiLoading: false,
  isDownloadingFileCsvApiLoading: false,
  isDownloadingFileOcrApiLoading: false,
  isDeletingCampaignApiLoading: false,
};

export const createCompaignActionCreator = (compaignData: any, compaignFile) => ({
  type: actionTypes.CREATE_COMPAIGN_ACTION.REQUEST,
  compaignData,
  compaignFile,
});
export const deleteCompaignActionCreator = (campaignData: any) => ({
  type: actionTypes.DELETE_CAMPAIGN_ACTION.REQUEST,
  campaignData,
});
export const uploadCompaignFileActionCreator = (compaignFile: any, fileUrl) => ({
  type: actionTypes.UPLOAD_FILE_ACTION.REQUEST,
  compaignFile,
  fileUrl,
});
export const getAllCampaignActionCreator = () => ({
  type: actionTypes.GET_ALL_CAMPAIGN_ACTION.REQUEST,
});
export const generateFileActionCreator = (campaign: any, campaignStatusIndex: Number, currentCampaignIndex: Number) => ({
  type: actionTypes.GENERATE_FILE_ACTION.REQUEST,
  campaign,
  campaignStatusIndex,
  currentCampaignIndex
});
export const downloadFileCsvActionCreator = (campaign: any) => ({
  type: actionTypes.DOWNLOAD_FILE_CSV_ACTION.REQUEST,
  campaign
});
export const downloadFileOcrActionCreator = (campaign: any) => ({
  type: actionTypes.DOWNLOAD_FILE_OCR_ACTION.REQUEST,
  campaign
});
export const updateAllCampaign = (currentCampaign: any, state: any) => {
  const allCampaign = state.allCampaign;
  if (allCampaign && Object.entries(allCampaign)) {
   Object.entries(allCampaign)[currentCampaign.campaignStatusIndex][1][currentCampaign.currentCampaignIndex] = currentCampaign.updatedCampaign;
   return allCampaign;
  } else {
    return allCampaign;
  } 
};
export const updateAllCampaignAfterDeletion = (currentCampaign: any, state: any) => {
  const allCampaign = state.allCampaign;
  if (allCampaign && Object.entries(allCampaign)) {
    console.log(Object.entries(allCampaign)[currentCampaign.campaignStatusIndex][1])
   Object.entries(allCampaign)[currentCampaign.campaignStatusIndex][1].splice(currentCampaign.currentCampaignIndex, 1);
   return allCampaign;
  } else {
    return allCampaign;
  } 
};
export const compaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CREATE_COMPAIGN_ACTION.API_LOADING_START:
    case actionTypes.UPLOAD_FILE_ACTION.API_LOADING_START:
    case actionTypes.GET_ALL_CAMPAIGN_ACTION.API_LOADING_START:
      return { ...state, isApiLoading: true }
    case actionTypes.DELETE_CAMPAIGN_ACTION.API_LOADING_START:
      return { ...state, isDeletingCampaignApiLoading: true }
    case actionTypes.GENERATE_FILE_ACTION.API_LOADING_START:
        return { ...state, isGeneratingFileApiLoading: true }
    case actionTypes.DOWNLOAD_FILE_OCR_ACTION.API_LOADING_START:
        return { ...state, isDownloadingFileOcrApiLoading: true }
    case actionTypes.DOWNLOAD_FILE_CSV_ACTION.API_LOADING_START:
        return { ...state, isDownloadingFileCsvApiLoading: true }
    case actionTypes.CREATE_COMPAIGN_ACTION.API_LOADING_STOP:
    case actionTypes.UPLOAD_FILE_ACTION.API_LOADING_STOP:
    case actionTypes.GET_ALL_CAMPAIGN_ACTION.API_LOADING_STOP:
      return { ...state, isApiLoading: false }
    case actionTypes.DELETE_CAMPAIGN_ACTION.API_LOADING_STOP:
        return { ...state, isDeletingCampaignApiLoading: false }
    case actionTypes.GENERATE_FILE_ACTION.API_LOADING_STOP:
        return { ...state, isGeneratingFileApiLoading: false }
    case actionTypes.DOWNLOAD_FILE_OCR_ACTION.API_LOADING_STOP:
        return { ...state, isDownloadingFileOcrApiLoading: false }
    case actionTypes.DOWNLOAD_FILE_CSV_ACTION.API_LOADING_STOP:
        return { ...state, isDownloadingFileCsvApiLoading: false }
    case actionTypes.CREATE_COMPAIGN_ACTION.SUCCESS:
      return { ...state, createdCompaign: action.compaign }
    case actionTypes.GET_ALL_CAMPAIGN_ACTION.SUCCESS:
      return { ...state, allCampaign: action.allCampaign }
    case actionTypes.GENERATE_FILE_ACTION.SUCCESS:
        return { ...state, allCampaign: updateAllCampaign(action.currentCampaign, state) }
    case actionTypes.DELETE_CAMPAIGN_ACTION.SUCCESS:
        return { ...state, allCampaign: updateAllCampaignAfterDeletion(action.currentCampaign, state) }
    case authenticationActionTypes.LOGOUT_ACTION.SUCCESS:
      return initialState;
    default:
      return state;
  }
};
