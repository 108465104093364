import { combineReducers } from 'redux';
import { loginReducer } from './authentication';
import { errorReducer } from './error';
import { compaignReducer } from './compaign';
import { getProfileReducer } from './profile';

const appReducer = combineReducers({
    authentication: loginReducer,
    error: errorReducer,
    compaign: compaignReducer,
    profile: getProfileReducer,
});

const initialState = {};

const rootReducer = (state = initialState, action) => (appReducer(state, action));

export default rootReducer;
