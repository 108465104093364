
import { fork } from 'redux-saga/effects';
import { loginSagas, forgetPasswordSaga, initPasswordSagas, logoutSaga } from './authentication';
import { createCompaignSagas, uploadCompaignFileSagas, getAllCampaignSagas,
    generateCampaignSagas, downloadFileCsvSagas, downloadFileOcrSagas, deleteCampaignSagas } from './compaign';
import { getProfileSagas } from './profile';

export default function* rootSaga() {
    yield fork(loginSagas);
    yield fork(forgetPasswordSaga);
    yield fork(initPasswordSagas);
    yield fork(createCompaignSagas);
    yield fork(uploadCompaignFileSagas);
    yield fork(getAllCampaignSagas);
    yield fork(generateCampaignSagas);
    yield fork(downloadFileCsvSagas);
    yield fork(downloadFileOcrSagas);
    yield fork(logoutSaga);
    yield fork(getProfileSagas);
    yield fork(deleteCampaignSagas);
}

