import { takeLatest, call, put, select } from 'redux-saga/effects';
import { actionTypes } from './';
import * as Api from '../../services/Api';
import { authenticatedCall } from '../apiAuthorization';
import handleApiException from '../../services/handleApiException';

export function* getProfile(): SagaType {
  try {
    yield put({ type: actionTypes.GET_PROFILE_ACTION.API_LOADING_START });
    const response = yield authenticatedCall(Api.getProfile());
    yield put({ type: actionTypes.GET_PROFILE_ACTION.SUCCESS, profile: response });
    yield put({ type: actionTypes.GET_PROFILE_ACTION.API_LOADING_STOP });
  } catch (error) {
    yield handleApiException(error); 
    yield put({ type: actionTypes.GET_PROFILE_ACTION.API_LOADING_STOP });  
  }
}
export function* getProfileSagas(): SagaType {
  yield takeLatest(actionTypes.GET_PROFILE_ACTION.REQUEST, getProfile);
}