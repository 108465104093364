// @flow
import { connect } from 'react-redux';
import ForgetPassword from './ForgetPassword.component';
import { getIsLoading, sendCodeActionCreator } from '../../../modules/authentication';
import { getError } from '../../../modules/error';

const mapStateToProps = state => ({
    handledError: getError(state),
    isApiLoading: getIsLoading(state),
});
const mapDispatchToProps = ({
    onSendCode: sendCodeActionCreator,
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPassword);
