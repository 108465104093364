// @flow

import font from './font';
import colors from './colors';

const GRID_MULTIPLIER = 8;

export default {
  font,
  colors,
  toasts: {
    error: {
      backgroundColor: 'black',
      textColor: 'white',
    },
  },
  margins: {
    xxsm: GRID_MULTIPLIER / 4,
    xsm: GRID_MULTIPLIER / 2,
    sm: GRID_MULTIPLIER,
    m: GRID_MULTIPLIER * 2,
    l: GRID_MULTIPLIER * 3,
    lg: GRID_MULTIPLIER * 4, // 32
    lgg: GRID_MULTIPLIER * 5, // 40
    xl: GRID_MULTIPLIER * 6,
    xxl: GRID_MULTIPLIER * 7,
    xxxl: GRID_MULTIPLIER * 8,
    xxxxl: GRID_MULTIPLIER * 9,
  },
  paddings: {
    xxsm: GRID_MULTIPLIER / 4,
    xsm: GRID_MULTIPLIER / 2,
    sm: GRID_MULTIPLIER,
    m: GRID_MULTIPLIER * 2,
    l: GRID_MULTIPLIER * 3,
    lg: GRID_MULTIPLIER * 4, // 32
    lgg: GRID_MULTIPLIER * 5, // 40
    xl: GRID_MULTIPLIER * 6,
    xxl: GRID_MULTIPLIER * 7,
    xxxl: GRID_MULTIPLIER * 8,
    eightxl: GRID_MULTIPLIER * 11,
  },
  radiuses: {
    sm: 2,
    m: 4,
    xl: 12.5,
    lg: 15,
    xxl: 20,
    xxxl: 30.5,
    circle: 50,
  },
};
