// @flow

export const actionTypes = {
  LOGIN_ACTION: {
    REQUEST: 'LOGIN_ACTION_REQUEST',
    SUCCESS: 'LOGIN_ACTION_SUCCESS',
    API_LOADING_START: 'LOGIN_ACTION_API_LOADING_START',
    API_LOADING_STOP: 'LOGIN_ACTION_API_LOADING_STOP',
  },
  LOGOUT_ACTION: {
    REQUEST: 'LOGOUT_ACTION_REQUEST',
    SUCCESS: 'LOGOUT_ACTION_SUCCESS',
    API_LOADING_START: 'LOGOUT_ACTION_API_LOADING_START',
    API_LOADING_STOP: 'LOGOUT_ACTION_API_LOADING_STOP',
  },
  FORGET_PASSWORD_ACTION: {
    REQUEST: 'FORGET_PASSWORD_ACTION_REQUEST',
    SUCCESS: 'FORGET_PASSWORD_ACTION_SUCCESS',
    API_LOADING_START: 'FORGET_PASSWORD_ACTION_API_LOADING_START',
    API_LOADING_STOP: 'FORGET_PASSWORD_ACTION_API_LOADING_STOP',
  },
  INIT_PASSWORD_ACTION: {
    REQUEST: 'INIT_PASSWORD_ACTION_REQUEST',
    SUCCESS: 'INIT_PASSWORD_ACTION_SUCCESS',
    API_LOADING_START: 'INIT_PASSWORD_ACTION_API_LOADING_START',
    API_LOADING_STOP: 'INIT_PASSWORD_ACTION_API_LOADING_STOP',
  },
  SET_SESSION_ACCESS_TOKEN_ACTION: 'SET_SESSION_ACCESS_TOKEN_ACTION',
};

const initialState = {
  isApiLoading: false,
  userInformation: null,
  accessToken: null,
};

export const loginActionCreator = (authenticationData: any) => ({
  type: actionTypes.LOGIN_ACTION.REQUEST,
  authenticationData,
});
export const initPasswordActionCreator = (initPasswordData: any) => ({
  type: actionTypes.INIT_PASSWORD_ACTION.REQUEST,
  initPasswordData,
});
export const sendCodeActionCreator = (email: String) => ({
  type: actionTypes.FORGET_PASSWORD_ACTION.REQUEST,
  email,
});
export const logoutActionCreator = (email: String) => ({
  type: actionTypes.LOGOUT_ACTION.REQUEST,
  email,
});
export const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_ACTION.API_LOADING_START:
    case actionTypes.FORGET_PASSWORD_ACTION.API_LOADING_START:
    case actionTypes.INIT_PASSWORD_ACTION.API_LOADING_START:
    case actionTypes.LOGOUT_ACTION.API_LOADING_START:
      return { ...state, isApiLoading: true }
    case actionTypes.LOGIN_ACTION.API_LOADING_STOP:
    case actionTypes.FORGET_PASSWORD_ACTION.API_LOADING_STOP:
    case actionTypes.INIT_PASSWORD_ACTION.API_LOADING_STOP:
    case actionTypes.LOGOUT_ACTION.API_LOADING_STOP:
        return { ...state, isApiLoading: false }
    case actionTypes.LOGIN_ACTION.SUCCESS:
        return {
          ...state,
          userInformation: action.userInformation,
          accessToken: action.accessToken,
          };
    case actionTypes.SET_SESSION_ACCESS_TOKEN_ACTION:
      return { ...state, accessToken: action.sessionToken };
    case actionTypes.LOGOUT_ACTION.SUCCESS:
      return initialState;
    default:
      return state;
  }
};
