// @flow
import { actionTypes as loginActionType } from '../authentication';
import { actionTypes as compaignActionTypes } from '../compaign';
import { actionTypes as authenticationActionTypes } from '../authentication';

export const actionTypes = {
  ERROR_ACTION: {
    REQUEST: 'ERROR_ACTION',
  },
};

const initialState = {
  errorText: null,
};

export const errorActionCreator = () => ({
  type: actionTypes.ERROR_ACTION,
});


export const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case loginActionType.LOGIN_ACTION.REQUEST:
    case loginActionType.FORGET_PASSWORD_ACTION.REQUEST:
    case loginActionType.INIT_PASSWORD_ACTION.REQUEST:
    case compaignActionTypes.CREATE_COMPAIGN_ACTION.REQUEST:
    case compaignActionTypes.UPLOAD_FILE_ACTION.REQUEST:
    case compaignActionTypes.GET_ALL_CAMPAIGN_ACTION.REQUEST:
    case compaignActionTypes.GENERATE_FILE_ACTION.REQUEST:
    case compaignActionTypes.DOWNLOAD_FILE_OCR_ACTION.REQUEST:
    case compaignActionTypes.DOWNLOAD_FILE_CSV_ACTION.REQUEST:
    case compaignActionTypes.DELETE_CAMPAIGN_ACTION.REQUEST:
    return { ...state, errorText: null }
    case actionTypes.ERROR_ACTION:
    return { ...state, errorText: action.error }
    case authenticationActionTypes.LOGOUT_ACTION.SUCCESS:
        return initialState;
    default:
      return state;
  }
};