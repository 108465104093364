// @flow
import { connect } from 'react-redux';
import NewCompaign from './NewCompaign.component';
import { createCompaignActionCreator, uploadCompaignFileActionCreator, getIsLoading, getCreatedCompaign } from '../../../modules/compaign';
import { getError } from '../../../modules/error';
import { getUserProfile, logoutActionCreator } from '../../../modules/authentication';
import { getProfileData, getProfileActionCreator } from '../../../modules/profile';

const mapStateToProps = state => ({
  errorText: getError(state),
  isApiLoading: getIsLoading(state),
  createdCompaign: getCreatedCompaign(state),
  userProfile: getUserProfile(state),
  profileData: getProfileData(state),
});
const mapDispatchToProps = ({
  onCreateCompaign: createCompaignActionCreator,
  onUploadCompaignAgain: uploadCompaignFileActionCreator,
  onLogout: logoutActionCreator,
  onGetProfile: getProfileActionCreator,
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCompaign);
