import React from 'react';
import { Box } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import appStyle from '../../appStyle';
import translate from '../../services/Translation';
import { HeaderBar, CampaignStatus, CampaignItem, CampaignRunningStatus, CampaignPendingStatus, CampaignInErrorStatus, CampaignClosedStatus } from './components';

const styles = {
    container: {
        width: '100%',
        minHeight: '100%',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: appStyle.colors.lightGrey,
        overflow: 'hidden',
    },
    leftPanel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: appStyle.margins.l,
        paddingRight: appStyle.margins.l,
        paddingTop: appStyle.paddings.m,
        width: '15%',
        minHeight: '100%',
        boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.5)',
        backgroundColor: appStyle.colors.secondary,
    },
     image: {
         maxHeight: 80,
         minWidth: 150,
         maxWidth: '16vw',
     },
     compaignButton: {
         borderRadius: appStyle.radiuses.xxxl,
         backgroundColor: appStyle.colors.primary,
         height: 61,
         width: 198,
         boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.5)',
         marginTop: appStyle.margins.xl,
         display: 'flex',
         alignItems: 'center',
         color: appStyle.colors.secondary,
         border: 0,
         marginBottom: appStyle.margins.xl,
         cursor: 'pointer',

     },
     addIcon: {
        fontSize: appStyle.font.sizes.xxl,
        color: appStyle.colors.secondary,
        paddingLeft: appStyle.paddings.l,
        paddingRight: appStyle.paddings.m,
      },
      detailsContainer: {
          width: '100%',
          minHeight: '100%',
          display: 'flex',
          flexDirection: 'row',
          paddingTop: appStyle.paddings.xxsm,
      },
      compaignArrayContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '40%',
        height: '100vh',
        paddingTop: appStyle.paddings.xxxl,
        paddingLeft: appStyle.paddings.xxsm,
        paddingRight: appStyle.paddings.xxsm,
        overflow: 'scroll',
      },
      compaignDetailsContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: appStyle.paddings.xxxl,
        paddingLeft: appStyle.paddings.eightxl,
        width: '60%',
        minHeight: '100%',
        backgroundColor: appStyle.colors.pageBackground,
      },
      campaignTitle: {
          fontSize: appStyle.font.sizes.l,
          fontWeight: 'bold',
          paddingLeft: appStyle.paddings.l,
      },
      fieldError: {
        textAlign: 'center',
        color: appStyle.colors.red,
     },
}
type PropsType = {
    handledError: String,
    isApiLoading: Boolean,
    isGeneratingLoading: Boolean,
    isDownloadingOcrLoading: Boolean,
    isDownloadingCsvLoading: Boolean,
    isDeletingCampaignApiLoading: Boolean,
    onGetAllCampaign: () => void,
    allCampaign: any,
    onGenerate: (campaign: any) => void,
    onDownloadFileCsv: (campaign: any) => void,
    onDownloadFileOcr: (campaign: any) => void,
    onLogout: (email: String) => void,
    handledError: String,
    userProfile: any,
    onDeletecampaign: (campaignData: any) => void,
};

const campaignStatus = [
    translate('campaignPendingStatus'), translate('campaignRunningStatus'), translate('campaignClosedStatus'), translate('campaignInErrorStatus'),
];


class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStatusIndex: 1,
            campaignByStatus: (props.allCampaign && Object.entries(props.allCampaign)) ? Object.entries(props.allCampaign)[1][1] : [],
            selectedItemIndex: 0,
            anchorEl: null,
        };
    }
    componentWillMount() {
        this.props.onGetAllCampaign();
    }
    componentDidUpdate(prevProps) {
        if (!this.props.allCampaign) return;
        if (prevProps.allCampaign !== this.props.allCampaign) this.setState({ campaignByStatus: Object.entries(this.props.allCampaign)[this.state.activeStatusIndex][1]});
    }
    onCampaignStatusChange(statusIndex: Number) {
        this.setState({ activeStatusIndex: statusIndex });
        this.setState({ selectedItemIndex: 0 });
        const currentCampaign = (this.props.allCampaign && Object.entries(this.props.allCampaign)) ? Object.entries(this.props.allCampaign)[statusIndex][1] : [];
        this.setState({ campaignByStatus: currentCampaign });
        this.props.onGetAllCampaign();
    }
    onSelectItem(index: Number) {
        this.setState({ selectedItemIndex: index });
    }
    calculateFillRate(campaign: any) {
        const meterNumber = campaign && campaign.analytics ? campaign.analytics.nbClients : '-';
        const totalSended = campaign && campaign.analytics && campaign.analytics.sended ? campaign.analytics.sended.total : 0;
        const totalAnswered = campaign && campaign.analytics && campaign.analytics.answered ? campaign.analytics.answered.total : 0;
        const totalReceived = campaign && campaign.analytics && campaign.analytics.received ? campaign.analytics.received.total : 0;
        const totalError = campaign && campaign.analytics && campaign.analytics.error ? campaign.analytics.error.total : 0;
        const fillRate = (totalSended > 0) ? totalAnswered / totalSended * 100: 0;
        const fillRateWithError = (totalReceived > 0) ? totalAnswered / totalReceived * 100 : 0;
        return totalError > 0 ? fillRateWithError : fillRate;
    }
    /*calculateFillRate(campaign: any) {
        const meterNumber = campaign.analytics ? campaign.analytics.nbClients : 0;
        const totalReceived = campaign.analytics ? campaign.analytics.answered.total : 0;
        if (!meterNumber || meterNumber === 0) return 0;
        return totalReceived / meterNumber * 100;
    }*/
    onGenerateCampaign(campaign: Number) {
       this.props.onGenerate(campaign, this.state.activeStatusIndex, this.state.selectedItemIndex);
    }
    onMenuClick(email: String) {
        this.props.onLogout(email);
    }
    deleteCampaign(campaign: any) {
        const campaignDataDeletion = {
            campaignId: campaign.campaignId,
            campaignStatusIndex: this.state.activeStatusIndex,
            currentCampaignIndex: this.state.selectedItemIndex,
        };
        this.props.onDeletecampaign(campaignDataDeletion);
    }
    props: PropsType;

    render() {
        const { campaignByStatus, selectedItemIndex } = this.state;
        const { userProfile } = this.props;
        const currentSelectedCampaign = campaignByStatus ? campaignByStatus[selectedItemIndex] : null;
        const email = userProfile && userProfile.idToken && userProfile.idToken.payload ? userProfile.idToken.payload.email: '';
        const status = campaignStatus[this.state.activeStatusIndex];
        return (
            <Box style={styles.container}>
              <Box style={styles.leftPanel}>
              <img src="assets/images/ressources/dashboardAppLogo.png" style={styles.image}/>
              <button style={styles.compaignButton} onClick={() => this.props.history.push('/newCompaign')}>
                  <Add style={styles.addIcon}/>
                  <p style={{ fontSize: appStyle.font.sizes.xs }}>{translate('newCompaignButtonTitle')}</p>
              </button>
              <Box>
              {(this.props.allCampaign && Object.entries(this.props.allCampaign)) ?
                    Object.entries(this.props.allCampaign).map((campaign, index) => {
                       return (<CampaignStatus
                            key={index}
                            campaignStatusName={campaignStatus[index]}
                            campaignCount={campaign[1].length} campaignStatusColor={appStyle.colors.campaignStatus[campaign[0].toLowerCase()]}
                            activeStatusIndex={this.state.activeStatusIndex === index}
                            onPress={() => this.onCampaignStatusChange(index)}
                            />)
                      })
                  :
                    campaignStatus.map((status, index) => 
                    (<CampaignStatus 
                        key={index}
                        campaignStatusName={status} 
                        campaignCount={0} 
                        activeStatusIndex={this.state.activeStatusIndex === index}
                        onPress={() => this.setState({ activeStatusIndex: index })}
                        />))
                  }
              </Box>
              <p 
                style={{ 
                    color: appStyle.colors.warmGrey,
                    fontStyle: 'italic',
                    fontSize: appStyle.font.sizes.xxs,
                    marginTop: appStyle.margins.xxxxl*2,
                }}
              >
                {translate('appVersion')}
              </p>
              </Box>
              <Box style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <HeaderBar email={email} onPress={() => this.onMenuClick(email)}/>
                <Box style={styles.detailsContainer}>
                    <Box style={styles.compaignArrayContainer}>
                        <p style={styles.campaignTitle}>{campaignStatus[this.state.activeStatusIndex]}</p>
                        {this.state.campaignByStatus && this.state.campaignByStatus.length ?
                            this.state.campaignByStatus.map((campaign, index) => {
                                return (
                                <CampaignItem
                                    key={index}
                                    campaignName={campaign.name}
                                    duration={campaign.duration}
                                    isSelectedItem={this.state.selectedItemIndex === index}
                                    onPress={() => this.onSelectItem(index)}
                                    fillRate={this.calculateFillRate(campaign)}
                                />)
                            })
                            : null
                        }
                    </Box>
                    <Box style={styles.compaignDetailsContainer}>
                        {(currentSelectedCampaign && (status === translate('campaignRunningStatus'))) &&
                            <CampaignRunningStatus 
                                campaign={currentSelectedCampaign}
                                onGenerateFile={() => currentSelectedCampaign && this.onGenerateCampaign(currentSelectedCampaign)}
                                isGeneratingLoading={this.props.isGeneratingLoading}
                                onDownload={() => this.props.onDownloadFileCsv(currentSelectedCampaign)}
                                isDownloadingLoading={this.props.isDownloadingLoading}
                            />
                        }
                        {(currentSelectedCampaign && (status === translate('campaignPendingStatus'))) &&
                            <CampaignPendingStatus 
                                campaign={currentSelectedCampaign}
                            />
                        }
                        {(currentSelectedCampaign && (status === translate('campaignInErrorStatus'))) &&
                            <CampaignInErrorStatus 
                                campaign={currentSelectedCampaign}
                                isApiLoading={this.props.isDeletingCampaignApiLoading}
                                onPress={() => this.deleteCampaign(currentSelectedCampaign)}
                            />
                        }
                        {(currentSelectedCampaign && (status === translate('campaignClosedStatus'))) &&
                            <CampaignClosedStatus 
                                campaign={currentSelectedCampaign}
                                onDownloadFileCsv={() => this.props.onDownloadFileCsv(currentSelectedCampaign)}
                                onDownloadFileOcr={() => this.props.onDownloadFileOcr(currentSelectedCampaign)}
                                isDownloadingOcrLoading={this.props.isDownloadingOcrLoading}
                                isDownloadingCsvLoading={this.props.isDownloadingCsvLoading}
                                isApiLoading={this.props.isDeletingCampaignApiLoading}
                                onPress={() => this.deleteCampaign(currentSelectedCampaign)}
                            />
                        }
                        {this.props.handledError && 
                            <p style={styles.fieldError}>
                               {this.props.handledError}
                            </p>
                        }
                    </Box>  
                </Box>
              </Box>
            </Box>
        )
    }
}

export default Dashboard; 