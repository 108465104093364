import React from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import CalendarToday from '@material-ui/icons/CalendarToday';
import Speed from '@material-ui/icons/Speed';
import Textsms from '@material-ui/icons/Textsms';
import MailOutline from '@material-ui/icons/MailOutline';
import DoneAll from '@material-ui/icons/DoneAll';
import PieChart from '@material-ui/icons/PieChart';
import appStyle from '../../../../appStyle';
import translate from '../../../../services/Translation';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
    title: {
        fontSize: appStyle.font.sizes.l,
        fontWeight: 'bold',
        marginBottom: appStyle.margins.xxl,
    },
    subtitle: {
        fontSize: appStyle.font.sizes.small,
        fontWeight: 'bold',
    },
    dateContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: appStyle.margins.m,
    },
    infoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: appStyle.margins.m,
        paddingRight: appStyle.paddings.eightxl,
    },
    subInfoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: appStyle.margins.xsm,
        paddingRight: appStyle.paddings.eightxl,
    },
    icon: {
        height: 18,
        width: 18,
        borderRadius: appStyle.radiuses.m,
        backgroundColor: appStyle.colors.primary,
        color: appStyle.colors.secondary,
        padding: appStyle.paddings.sm,
    },
    performIcon: {
        height: 18,
        width: 18,
        borderRadius: appStyle.radiuses.m,
        backgroundColor: appStyle.colors.greens.green_1,
        color: appStyle.colors.secondary,
        padding: appStyle.paddings.sm,
    },
    performIconError: {
        height: 18,
        width: 18,
        borderRadius: appStyle.radiuses.m,
        backgroundColor: appStyle.colors.red,
        color: appStyle.colors.secondary,
        padding: appStyle.paddings.sm,
    },
    separator: {
        height: 1,
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: appStyle.colors.warmGrey,
        marginRight: appStyle.paddings.eightxl,
        marginTop: appStyle.margins.l,
        marginBottom: appStyle.margins.l,
    },
    downloadContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    generateButton: {
        backgroundColor: appStyle.colors.primary,
        color: appStyle.colors.secondary,
        borderRadius: appStyle.radiuses.m,
        padding: appStyle.paddings.sm,
        minHeight: 36,
        outline: 'none',
        border: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.5)',
        cursor: 'pointer',
    },
    downloadButton: {
        backgroundColor: appStyle.colors.secondary,
        color: appStyle.colors.primary,
        borderRadius: appStyle.radiuses.m,
        padding: appStyle.paddings.sm,
        minHeight: 36,
        outline: 'none',
        border: 0,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: appStyle.margins.m,
        cursor: 'pointer',
    },
}
type PropsType = {
    campaign: any,
    onDownload: () => void,
    onGenerateFile: () => void,
    isGeneratingLoading: Boolean,
    isDownloadingLoading: Boolean,
};


class CampaignRunningStatus extends React.Component {
    constructor() {
        super();
    }
    props: PropsType;

    calculateFillRate() {
        const { campaign } = this.props;
        const meterNumber = campaign && campaign.analytics ? campaign.analytics.nbClients : '-';
        const totalSended = campaign && campaign.analytics && campaign.analytics.sended ? campaign.analytics.sended.total : 0;
        const totalAnswered = campaign && campaign.analytics && campaign.analytics.answered ? campaign.analytics.answered.total : 0;
        const totalReceived = campaign && campaign.analytics && campaign.analytics.received ? campaign.analytics.received.total : 0;
        const totalError = campaign && campaign.analytics && campaign.analytics.error ? campaign.analytics.error.total : 0;
        const fillRate = (totalSended > 0) ? Math.round(totalAnswered / totalSended * 100).toFixed(1) : 0;
        const fillRateWithError = (totalReceived > 0) ? Math.round(totalAnswered / totalReceived * 100).toFixed(1) : 0;
        console.log('fillRate', fillRate, 'fillRateWithError', fillRateWithError, 'error', totalError > 0);
        const returnedFillRate = totalError > 0 ? fillRateWithError : fillRate;
        return returnedFillRate;
    }

    render() {
        const { campaign } = this.props;
        const compaignName = campaign ? campaign.name : '-';
        const fromDate = campaign && campaign.plannedProcessDate ? moment(campaign.plannedProcessDate).format('DD/MM/YYYY') : '-';
        const toDate = campaign && campaign.plannedEndDate ? moment(campaign.plannedEndDate).format('DD/MM/YYYY') : '-';
        const duration = campaign && campaign.duration ? campaign.duration : '-';
        const meterNumber = campaign && campaign.analytics ? campaign.analytics.nbClients : '-';
        const sentSmsNumber = campaign && campaign.analytics && campaign.analytics.sended ? campaign.analytics.sended.phone : '-';
        const receivedSmsNumber = campaign && campaign.analytics && campaign.analytics.received ? campaign.analytics.received.phone : '-';
        const receivedEmailNumber = campaign && campaign.analytics && campaign.analytics.received ? campaign.analytics.received.email : '-';
        const sentEmailNumber = campaign && campaign.analytics && campaign.analytics.sended ? campaign.analytics.sended.email : '-';
        const sentSmsInErrorNumber = campaign && campaign.analytics && campaign.analytics.error ? campaign.analytics.error.phone : '-';
        const sentEmailInErrorNumber = campaign && campaign.analytics && campaign.analytics.error ? campaign.analytics.error.email : '-';
        const indexEntredNumber = campaign && campaign.analytics && campaign.analytics.answered ? campaign.analytics.answered.total : '-';
        const byOcr = campaign && campaign.analytics && campaign.analytics.entryMethod ? campaign.analytics.entryMethod.photo : '-';
        const byVoice = campaign && campaign.analytics && campaign.analytics.entryMethod ? campaign.analytics.entryMethod.voice : '-';
        const manually = campaign && campaign.analytics && campaign.analytics.entryMethod ? campaign.analytics.entryMethod.manual : '-';
        const isGenerating = (campaign && campaign.report) ? campaign.report.state === 'GENERATING' : false;
        const fileGenerateDate = (campaign && campaign.report) ? moment(campaign.report.stateDate).format('DD/MM/YYYY HH:mm:ss'): null;
        const isDownloadEnabled = (campaign && campaign.report) ? (campaign.report.url) : false;
        const isStateOnGoing = (campaign) ? campaign.state === 'ONGOING' : false;
        let generateButtonStyle = styles.generateButton;
        let downloadButtonStyle = styles.downloadButton;
        if (isGenerating || !isStateOnGoing) generateButtonStyle = { ...generateButtonStyle, backgroundColor: appStyle.colors.warmGrey };
        if (isGenerating || !isDownloadEnabled || !isStateOnGoing) downloadButtonStyle = { 
            ...downloadButtonStyle,
            backgroundColor: appStyle.colors.warmGrey,
            color: appStyle.colors.secondary,
        };
        return (
            <Box style={styles.container}>
                <p style={styles.title}>{compaignName}</p>
                <p style={styles.subtitle}>{translate('campaignInformationTitle')}</p>
                <Box style={styles.infoContainer}>
                    <CalendarToday style={styles.icon}/>
                    <p style={{ marginLeft: appStyle.margins.m, color: appStyle.colors.warmGrey }}>{translate('fromDate')}</p>
                    <p style={{ marginLeft: 'auto' }}>{fromDate}</p>
                    <p style={{ marginLeft: 'auto', color: appStyle.colors.warmGrey }}>{translate('toDate')}</p>
                    <p style={{ marginLeft: 'auto' }}>{toDate}</p>
                    <p style={{ marginLeft: 'auto', color: appStyle.colors.warmGrey }}>{translate('durationTitle')}</p>
                    <p style={{ marginLeft: 'auto' }}>{duration}</p>
                </Box>
                <Box style={styles.infoContainer}>
                    <Speed style={styles.icon}/>
                    <p style={{ marginLeft: appStyle.margins.m, color: appStyle.colors.warmGrey }}>{translate('meterNumberTitle')}</p>
                    <p style={{ marginLeft: 'auto' }}>{meterNumber}</p>
                </Box>
                <p style={styles.subtitle}>{translate('downloadFileTitle')}</p>
                <Box style={styles.downloadContainer}>
                    <button 
                        style={generateButtonStyle}
                        onClick={() => this.props.onGenerateFile()}
                        disabled={isGenerating || !isStateOnGoing}
                    >
                    {this.props.isGeneratingLoading && 
                        <CircularProgress size={24} style={{ color: appStyle.colors.white, marginRight: appStyle.margins.sm }} />}
                        {translate('generateButtonTitle')}
                    </button>
                    <button
                        style={downloadButtonStyle}
                        onClick={() => this.props.onDownload()}
                        disabled={isGenerating || !isDownloadEnabled || !isStateOnGoing}
                    >
                        {this.props.isDownloadingLoading && 
                        <CircularProgress size={24} style={{ color: appStyle.colors.primary, marginRight: appStyle.margins.sm }} />}
                        {translate('downloadButtonTitle')}
                    </button>
                </Box>
                {fileGenerateDate &&
                <p
                   style={{ 
                       color: appStyle.colors.warmGrey,
                       fontSize: appStyle.font.sizes.xxs,
                       textAlign: 'center',
                       fontStyle: 'italic',
                       marginTop: appStyle.margins.sm,
                    }}
                >
                    {`${translate('generateFileStatusDate')} ${fileGenerateDate}`}
                </p>
                }
                {isGenerating &&
                <p
                   style={{ 
                       color: appStyle.colors.warmGrey,
                       fontSize: appStyle.font.sizes.xxs,
                       textAlign: 'center',
                       fontStyle: 'italic',
                       marginTop: appStyle.margins.sm,
                    }}
                >
                    {translate('fileGeneratingTitle')}
                </p>
                }
                <Box style={styles.separator}/>
                <p style={styles.subtitle}>{translate('campaignPerformanceTitle')}</p>
                <p style={styles.smallText}>{translate('campaignPerformanceSending')}</p>
                <Box style={styles.infoContainer}>
                    <Textsms style={styles.performIcon}/>
                    <p style={{ marginLeft: appStyle.margins.m, color: appStyle.colors.warmGrey }}>{translate('sentSmsTitle')}</p>
                    <p style={{ marginLeft: 'auto' }}>{sentSmsNumber}</p>
                </Box>
                    <Box style={styles.infoContainer}>
                        <Textsms style={styles.performIcon}/>
                        <p style={{ marginLeft: appStyle.margins.m, color: appStyle.colors.warmGrey }}>{translate('receivedSmsTitle')}</p>
                        <p style={{ marginLeft: 'auto' }}>{receivedSmsNumber}</p>
                    </Box>
                <Box style={styles.infoContainer}>
                    <Textsms style={styles.performIconError}/>
                    <p style={{ marginLeft: appStyle.margins.m, color: appStyle.colors.warmGrey }}>{translate('sentSmsErrorTitle')}</p>
                    <p style={{ marginLeft: 'auto' }}>{sentSmsInErrorNumber}</p>
                </Box>
                <Box style={styles.infoContainer}>
                    <MailOutline style={styles.performIcon}/>
                    <p style={{ marginLeft: appStyle.margins.m, color: appStyle.colors.warmGrey }}>{translate('sentEmailTitle')}</p>
                    <p style={{ marginLeft: 'auto' }}>{sentEmailNumber}</p>
                </Box>
                <Box style={styles.infoContainer}>
                    <MailOutline style={styles.performIcon}/>
                    <p style={{ marginLeft: appStyle.margins.m, color: appStyle.colors.warmGrey }}>{translate('receivedEmailTitle')}</p>
                    <p style={{ marginLeft: 'auto' }}>{receivedEmailNumber}</p>
                </Box>
                <Box style={styles.infoContainer}>
                    <MailOutline style={styles.performIconError}/>
                    <p style={{ marginLeft: appStyle.margins.m, color: appStyle.colors.warmGrey }}>{translate('sentEmailErrorTitle')}</p>
                    <p style={{ marginLeft: 'auto' }}>{sentEmailInErrorNumber}</p>
                </Box>

                <p style={styles.smallText}>{translate('campaignPerformanceMeterReading')}</p>
                <Box style={styles.infoContainer}>
                    <PieChart style={styles.performIcon}/>
                    <p style={{ marginLeft: appStyle.margins.m, color: appStyle.colors.warmGrey }}>{translate('fillRateTitle')}</p>
                    <p style={{ marginLeft: 'auto', color: appStyle.colors.black, fontSize: appStyle.font.sizes.l, fontWeight: 'bold'  }}>{`${this.calculateFillRate()} %`}</p>
                </Box>
                <Box style={styles.subInfoContainer}>
                    <DoneAll style={styles.performIcon}/>
                    <p style={{ marginLeft: appStyle.margins.m, color: appStyle.colors.warmGrey }}>{translate('indexEntredTitle')}</p>
                    <p style={{ marginLeft: 'auto' , color: appStyle.colors.black, fontSize: appStyle.font.sizes.l, fontWeight: 'bold' }}>{indexEntredNumber}</p>
                </Box>
                <Box style={styles.subInfoContainer}>
                    <p style={{ marginLeft: appStyle.margins.xl, color: appStyle.colors.warmGrey }}>{translate('manually')}</p>
                    <p style={{ marginLeft: 'auto' }}>{manually}</p>
                </Box>
                <Box style={styles.subInfoContainer}>
                    <p style={{ marginLeft: appStyle.margins.xl, color: appStyle.colors.warmGrey }}>{translate('byVoice')}</p>
                    <p style={{ marginLeft: 'auto' }}>{byVoice}</p>
                </Box>
                <Box style={styles.subInfoContainer}>
                    <p style={{ marginLeft: appStyle.margins.xl, color: appStyle.colors.warmGrey }}>{translate('byOcr')}</p>
                    <p style={{ marginLeft: 'auto' }}>{byOcr}</p>
                </Box>
            </Box>
        )
    }
}

export default CampaignRunningStatus; 