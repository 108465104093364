import React from 'react';
import { connect } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { Login, ResetPassword, Dashboard, ForgetPassword, InitializePassword, NewCompaign, NoMatchPageError } from './pages';
import history from './history';
import { getUserProfile } from './modules/authentication';
import { getAllCampaignActionCreator } from './modules/compaign';


type PropsType = {
    userProfile: any,
};

function PrivateRoute ({component: Component, authed, ...rest}) {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (authed === true) {
              return(<Component {...props} />);
          } else {
              return(<Redirect to={{ pathname: '/login', state: {from: props.location }}} />)
          }
        }}
      />
    )
  }

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isAuthenticated: props.userProfile ? true: false,
        };
    }
    componentDidUpdate(prevProps) {
      if (prevProps.userProfile !== this.props.userProfile) this.setState({ isAuthenticated: this.props.userProfile ? true : false })
    }
    managePrivateRoutes(routesHistory: any) {
          if (routesHistory.location.pathname === "/") return <PrivateRoute authed={this.state.isAuthenticated} path="/" component={Dashboard} />;
          if (routesHistory.location.pathname === "/dashboard") return <PrivateRoute authed={this.state.isAuthenticated} path="/dashboard" component={Dashboard} />;
          if (routesHistory.location.pathname === "/newCompaign") return <PrivateRoute authed={this.state.isAuthenticated} path="/newCompaign" component={NewCompaign} />;
          return <PrivateRoute authed={this.state.isAuthenticated} component={NoMatchPageError}/>
    }
    props: PropsType;
    render() {
        return (
        <Router history={history}>
            <Switch>
            <Route path="/login" exact component={Login}/>
            <Route path="/resetPassword" component={ResetPassword}/>
            <Route path="/forgetPassword" component={ForgetPassword}/>
            <Route path="/initializePassword" component={InitializePassword}/>
            {this.state.isAuthenticated && <Route path="/newCompaign" component={NewCompaign}/>}
            {this.state.isAuthenticated && <Route path="/dashboard" component={Dashboard}/>}
            {this.managePrivateRoutes(history)}
            </Switch>
       </Router>        
       )
    }
}

const mapStateToProps = (state) => ({
    userProfile: getUserProfile(state),
  });

const mapDispatchToProps = {
    onGetAllCampaign: getAllCampaignActionCreator,
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(App);