import request from 'superagent';
import Cognito from './Cognito';
import { END_POINT, BRAND_KEY, API_KEY } from '../environment';

export const addAuthorizationHeader = (apiCall: any, token: string) => {
  apiCall.set('Authorization', token)
  apiCall.set('x-api-key', API_KEY)
  apiCall.query({
    bk: BRAND_KEY,
    tsk: + new Date()
  });
  return apiCall;
};

export const login = (email: string, password: string) => {
    const cognitoService = new Cognito();
    return cognitoService.authenticateUser(email, password).then(result => (result));
  };
export const logout = (email: string) => {
    const cognitoService = new Cognito();
    return cognitoService.signOut(email);
}
export const forgetPassword = (email: string) => {
    const cognitoService = new Cognito();
    return cognitoService.forgotPassword(email);
  };
export const initPassword = (initPasswordData: any) => {
    const cognitoService = new Cognito()
    const { email, code, password } = initPasswordData;
    return cognitoService.confirmPassword(email, code, password);
};
export const getSessionToken = () => {
  const cognitoService = new Cognito();
  const validitySessionStatus = cognitoService.checkValiditySession();
  return validitySessionStatus;
};
export const createCompaign = (compaignData: any) => {
  return request
  .post(`${END_POINT}/admin/campaign`)
  .send({
      ...compaignData,
    })
};
export const allCompaign = () => {
  return request
  .get(`${END_POINT}/admin/campaign`)
};
export const getProfile = () => {
  return request
  .get(`${END_POINT}/admin/agent`)
};
export const generateCompaign = (campaignId: Number) => {
  return request
  .post(`${END_POINT}/admin/campaign/report`)
  .query({
      campaignId
    })
};
export const deleteCampaign = (campaignId: Number) => {
  return request
  .delete(`${END_POINT}/admin/campaign`)
  .query({
      campaignId
    })
};
export const refreshFileUrl = (campaignId: Number) => {
  return request
  .post(`${END_POINT}/admin/campaign/report/url`)
  .query({
      campaignId
    })
};
export const getFileOcrUrl = (campaignId: Number) => {
  return request
  .post(`${END_POINT}/admin/campaign/images/url`)
  .query({
      campaignId,
      bk: BRAND_KEY,
      tsk: + new Date()
    })
};
export const uploadCompaignFile = (uploadData: any) => {
        return new Promise((resolve, reject) => {
          fetch(uploadData.url, {
          method: 'PUT',
          body: uploadData.file,
        }).then(res => {
          if(res.ok) {
            resolve(res);
          }
          else {
            reject(res);
          }
        }).catch(error => reject(error))});
};