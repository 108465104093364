// @flow
import { connect } from 'react-redux';
import Login from './Login.component';
import { loginActionCreator, getIsLoading } from '../../../modules/authentication';
import { getError } from '../../../modules/error';

const mapStateToProps = state => ({
    handledError: getError(state),
    isApiLoading: getIsLoading(state),
});
const mapDispatchToProps = ({
    onLogin: loginActionCreator,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
