import { takeLatest, call, put, select } from 'redux-saga/effects';
import history from '../../history';
import { actionTypes } from './';
import * as Api from '../../services/Api';
import handleApiException from '../../services/handleApiException';
import { getProfile } from '../profile';
import { APP_NAME } from '../../environment';
import { AGENTS_GROUP } from '../../environment';
import translate from '../../services/Translation';
import { actionTypes as errorActionTypes } from '../error';

export const NEW_PASSWORD_REQUIRED_FLAG = 'NEW_PASSWORD_REQUIRED';

export function* login(action: any): SagaType {
  try {
    const { authenticationData } = action;
    yield put({ type: actionTypes.LOGIN_ACTION.API_LOADING_START });
    const response = yield call(Api.login, authenticationData);
    if (response.flag === NEW_PASSWORD_REQUIRED_FLAG) {
      yield put({ type: actionTypes.LOGIN_ACTION.API_LOADING_STOP });
      return yield history.push("/resetPassword", { email: authenticationData.email, password: authenticationData.password });
    }
    // Chek if user is from the good Area
    if (APP_NAME !== response.idToken.payload.zoneinfo) {
        return [
          yield put({ type: errorActionTypes.ERROR_ACTION, error: translate('unauthorizedActionSession') }),
          yield put({ type: actionTypes.LOGIN_ACTION.API_LOADING_STOP })
      ];
    }
    if (response.idToken.payload['cognito:groups'] === undefined) {
      return [
        yield put({ type: errorActionTypes.ERROR_ACTION, error: translate('unauthorizedActionSession') }),
        yield put({ type: actionTypes.LOGIN_ACTION.API_LOADING_STOP })
      ];
    }
    let groupmatched = false
    for (let i = 0; i < response.idToken.payload['cognito:groups'].length; i += 1) {
      if (AGENTS_GROUP === response.idToken.payload['cognito:groups'][i]) {
        groupmatched = true;
        break;
      }
    }
    if (groupmatched === false) {
      return [
        yield put({ type: errorActionTypes.ERROR_ACTION, error: translate('unauthorizedActionSession') }),
        yield put({ type: actionTypes.LOGIN_ACTION.API_LOADING_STOP })
      ];
    }


    const accessToken = response.getIdToken().getJwtToken();
    yield put({ type: actionTypes.LOGIN_ACTION.SUCCESS, userInformation: response, accessToken });
    yield put({ type: actionTypes.LOGIN_ACTION.API_LOADING_STOP });
    yield history.replace("/dashboard");
  } catch (error) {
    yield handleApiException(error); 
    yield put({ type: actionTypes.LOGIN_ACTION.API_LOADING_STOP });  }
}
export function* initPassword(action: any): SagaType {
  const { initPasswordData } = action;
  yield call(Api.initPassword, initPasswordData);
  yield history.replace("/login");
}

export function* initPasswordSagas(): SagaType {
  const requestActionType = actionTypes.INIT_PASSWORD_ACTION.REQUEST;
  yield takeLatest(requestActionType, initPassword);
}
export function* forgetPassword(action: any): SagaType {
  try {
    const { email } = action;
    yield put({ type: actionTypes.LOGIN_ACTION.API_LOADING_START });
    yield call(Api.forgetPassword, email);
    yield put({ type: actionTypes.LOGIN_ACTION.API_LOADING_STOP });
    yield history.push("/initializePassword", { email });
  } catch (error) {
    yield handleApiException(error); 
    yield put({ type: actionTypes.LOGIN_ACTION.API_LOADING_STOP });
  }
}
export function* logout(action: any): SagaType {
  try {
    const { email } = action;
    yield put({ type: actionTypes.LOGOUT_ACTION.API_LOADING_START });
    yield call(Api.logout, email);
    yield put({ type: actionTypes.LOGOUT_ACTION.API_LOADING_STOP });
    yield destroySession();
  } catch (error) {
    yield handleApiException(error);
    yield put({ type: actionTypes.LOGOUT_ACTION.API_LOADING_STOP });
  }
}
export function* destroySession() : SagaType {
  try {
    yield put({ type: actionTypes.LOGOUT_ACTION.SUCCESS });
    yield history.replace('/login');
  } catch (error) {
    yield handleApiException(error);
    yield put({ type: actionTypes.LOGOUT_ACTION.API_LOADING_STOP });
  }
}
export function* logoutSaga(): SagaType {
  const requestActionType = actionTypes.LOGOUT_ACTION.REQUEST;
  yield takeLatest(requestActionType, logout);
}
export function* forgetPasswordSaga(): SagaType {
  const requestActionType = actionTypes.FORGET_PASSWORD_ACTION.REQUEST;
  yield takeLatest(requestActionType, forgetPassword);
}
export function* loginSagas(): SagaType {
  yield takeLatest(actionTypes.LOGIN_ACTION.REQUEST, login);
}
