// @flow
import { actionTypes as authenticationActionTypes } from '../authentication';

export const actionTypes = {
  GET_PROFILE_ACTION: {
    REQUEST: 'GET_PROFILE_ACTION_REQUEST',
    SUCCESS: 'GET_PROFILE_ACTION_SUCCESS',
    API_LOADING_START: 'GET_PROFILE_ACTION_API_LOADING_START',
    API_LOADING_STOP: 'GET_PROFILE_ACTION_API_LOADING_STOP',
  },
};

const initialState = {
  profileData: null,
  isApiLoading: false,
};

export const getProfileActionCreator = () => ({
  type: actionTypes.GET_PROFILE_ACTION.REQUEST,
});


export const getProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PROFILE_ACTION.API_LOADING_START:
    return { ...state, isApiLoading: true }
    case actionTypes.GET_PROFILE_ACTION.API_LOADING_STOP:
      return { ...state, isApiLoading: false }
    case actionTypes.GET_PROFILE_ACTION.SUCCESS:
    return { ...state, profileData: action.profile }
    case authenticationActionTypes.LOGOUT_ACTION.SUCCESS:
        return initialState;
    default:
      return state;
  }
};