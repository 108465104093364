// @flow

const blue = {
  blue_1: 'rgb(0, 174, 199)',
};
const greys = {
  grey_1: '#8e8e8c',
  grey_2: '#bebebe',
  grey_3: '#f3f3f3',
};
const greens = {
  green_1: '#97bf0d',
};
const oranges = {
  orange_1: '#f5a623',
};
const white = 'white';
const reds = {
  red_1: 'red',
  red_2: '#f8001e',
};
export default {
 primary: blue.blue_1,
 secondary: white,
 pageBackground: white,
 white,
 warmGrey: greys.grey_1,
 lightGrey: greys.grey_3,
 red: reds.red_1,
 orange: oranges.orange_1,
 greens,
 campaignStatus: {
   pending: oranges.orange_1,
   running: greens.green_1,
   closed: greys.grey_2,
   inerror: reds.red_2,
 },
};
