import React from 'react';
import { Box } from '@material-ui/core';
import Send from '@material-ui/icons/Send';
import appStyle from '../../../../appStyle';
import translate from '../../../../services/Translation';

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    icon: {
        color: appStyle.colors.primary,
    },
    countContainer: {
        borderRadius: appStyle.radiuses.xl,
        height: 27,
        width: 48,
        backgroundColor: 'red',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    status: {
        paddingLeft: appStyle.paddings.m,
        paddingRight: appStyle.paddings.m,
    },
}
type PropsType = {
    campaignStatusName: String,
    campaignCount: Number,
    campaignStatusColor: String,
    onPress: () => void,
    activeStatusIndex: Boolean,
};


class CampaignStatus extends React.Component {
    constructor() {
        super();
    }
    props: PropsType;

    render() {
        let countContainerStyle = styles.countContainer;
        let statusTextStyle = styles.status;
        countContainerStyle = { ...countContainerStyle, backgroundColor: this.props.campaignStatusColor };
        if (this.props.activeStatusIndex) statusTextStyle = { ...statusTextStyle, fontWeight: 'bold' };
        return (
            <Box style={styles.container} onClick={() => this.props.onPress()}>
                <Send style={styles.icon}/>
                <p style={statusTextStyle}>{this.props.campaignStatusName}</p>
                <Box style={countContainerStyle}>
                    <p>{this.props.campaignCount}</p>
                </Box>
            </Box>
        )
    }
}

export default CampaignStatus; 