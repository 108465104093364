import { takeLatest, call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import history from '../../history';
import { actionTypes } from './';
import * as Api from '../../services/Api';
import { authenticatedCall } from '../apiAuthorization';
import handleApiException from '../../services/handleApiException';
import { get } from 'lodash';

export function* createCompaign(action: any): SagaType {
  try {
    const { compaignData, compaignFile } = action;
    yield put({ type: actionTypes.CREATE_COMPAIGN_ACTION.SUCCESS, compaign: null });
    yield put({ type: actionTypes.CREATE_COMPAIGN_ACTION.API_LOADING_START });
    const response = yield authenticatedCall(Api.createCompaign(compaignData));
    yield put({ type: actionTypes.CREATE_COMPAIGN_ACTION.SUCCESS, compaign: response });
    yield call(Api.uploadCompaignFile, { url: response.uploadFile.fileUrl, file: compaignFile });
    yield put({ type: actionTypes.CREATE_COMPAIGN_ACTION.SUCCESS, compaign: null });
    yield put({ type: actionTypes.CREATE_COMPAIGN_ACTION.API_LOADING_STOP });
    yield history.goBack();
  } catch (error) {
    yield handleApiException(error); 
    yield put({ type: actionTypes.CREATE_COMPAIGN_ACTION.API_LOADING_STOP });  }
}
export function* uploadFile(action: any): SagaType {
  try {
    const { compaignFile, fileUrl } = action;
    yield put({ type: actionTypes.UPLOAD_FILE_ACTION.API_LOADING_START });
    yield call(Api.uploadCompaignFile, { url: fileUrl , file: compaignFile })
    yield put({ type: actionTypes.UPLOAD_FILE_ACTION.API_LOADING_STOP });
    yield history.goBack();
  } catch (error) {
    yield handleApiException(error); 
    yield put({ type: actionTypes.UPLOAD_FILE_ACTION.API_LOADING_STOP });  }
}
export function* getAllCampaign(): SagaType {
  try {
    yield put({ type: actionTypes.GET_ALL_CAMPAIGN_ACTION.API_LOADING_START });
    yield put({ type: actionTypes.CREATE_COMPAIGN_ACTION.SUCCESS, compaign: null });
    let response = yield authenticatedCall(Api.allCompaign());
    yield put({ type: actionTypes.GET_ALL_CAMPAIGN_ACTION.SUCCESS, allCampaign: response });
    yield put({ type: actionTypes.GET_ALL_CAMPAIGN_ACTION.API_LOADING_STOP });
  } catch (error) {
    yield handleApiException(error); 
    yield put({ type: actionTypes.GET_ALL_CAMPAIGN_ACTION.API_LOADING_STOP });
    const errorCode = get(error, 'response.body.code') || error.code;
    if (errorCode === 'ERR_TECH_NOT_AUTHORIZED') {
      yield history.replace("/login");
    }
  }
}
export function* refreshFileUrl(action: any): SagaType {
  try {
    const { campaignId } = action;
    const response = yield authenticatedCall(Api.refreshFileUrl(campaignId));
    yield window.open(response.report.url);
    yield call(getAllCampaign);
    yield put({ type: actionTypes.DOWNLOAD_FILE_CSV_ACTION.API_LOADING_STOP });
  } catch (error) {
    yield handleApiException(error); 
    yield put({ type: actionTypes.DOWNLOAD_FILE_CSV_ACTION.API_LOADING_STOP });
  }
}
export function* getFileOcrUrl(action: any): SagaType {
  try {
    const { campaignId } = action;
    const response = yield authenticatedCall(Api.getFileOcrUrl(campaignId));
    yield window.open(response.indexImagesArchive.fileUrl);
    yield put({ type: actionTypes.DOWNLOAD_FILE_OCR_ACTION.API_LOADING_STOP });
  } catch (error) {
    yield handleApiException(error);
    yield put({ type: actionTypes.DOWNLOAD_FILE_OCR_ACTION.API_LOADING_STOP });
  }
}
export function* generateCampaign(action: any): SagaType {
  try {
    const { campaign, campaignStatusIndex, currentCampaignIndex } = action;
    yield put({ type: actionTypes.GENERATE_FILE_ACTION.API_LOADING_START });
    const response = yield authenticatedCall(Api.generateCompaign(campaign.campaignId));
    const returnedCampaign = {
      updatedCampaign: response,
      campaignStatusIndex,
      currentCampaignIndex
    }
    yield put({ type: actionTypes.GENERATE_FILE_ACTION.SUCCESS, currentCampaign: returnedCampaign });
    yield put({ type: actionTypes.GENERATE_FILE_ACTION.API_LOADING_STOP });
  } catch (error) {
    yield handleApiException(error); 
    yield put({ type: actionTypes.GENERATE_FILE_ACTION.API_LOADING_STOP });  
  }
}
export function* downloadFileCsv(action: any): SagaType {
  try {
    const { campaign } = action;
    yield put({ type: actionTypes.DOWNLOAD_FILE_CSV_ACTION.API_LOADING_START });
    const urlValidityDate = campaign.report && moment(campaign.report.urlValidity).format('YYYY-MM-DD HH:mm:ss');
    const now = moment().format('YYYY-MM-DD HH:mm:ss');
    if (campaign.report && urlValidityDate <= now) return yield call(refreshFileUrl, { campaignId: campaign.campaignId });
    yield window.open(campaign.report.url)
    yield put({ type: actionTypes.DOWNLOAD_FILE_CSV_ACTION.API_LOADING_STOP });
  } catch (error) {
    yield handleApiException(error);
    yield put({ type: actionTypes.DOWNLOAD_FILE_CSV_ACTION.API_LOADING_STOP });
  }
}
export function* downloadFileOcr(action: any): SagaType {
  try {
    const { campaign } = action;
    yield put({ type: actionTypes.DOWNLOAD_FILE_OCR_ACTION.API_LOADING_START });

    const urlValidityDate = campaign.report && moment(campaign.indexImagesArchive.urlValidity).format('YYYY-MM-DD HH:mm:ss');
    const now = moment().format('YYYY-MM-DD HH:mm:ss');
    if (campaign.indexImagesArchive && urlValidityDate <= now) return yield call(getFileOcrUrl, { campaignId: campaign.campaignId });
    yield window.open(campaign.indexImagesArchive.fileUrl)
    yield put({ type: actionTypes.DOWNLOAD_FILE_OCR_ACTION.API_LOADING_STOP });

  } catch (error) {
    yield handleApiException(error);
    yield put({ type: actionTypes.DOWNLOAD_FILE_OCR_ACTION.API_LOADING_STOP });
  }
}
export function* downloadFileCsvSagas(): SagaType {
  yield takeLatest(actionTypes.DOWNLOAD_FILE_CSV_ACTION.REQUEST, downloadFileCsv);
}
export function* downloadFileOcrSagas(): SagaType {
  yield takeLatest(actionTypes.DOWNLOAD_FILE_OCR_ACTION.REQUEST, downloadFileOcr);
}
export function* deleteCampaign(action: any): SagaType {
  try {
    const { campaignData } = action;
    yield put({ type: actionTypes.DELETE_CAMPAIGN_ACTION.API_LOADING_START });
    yield authenticatedCall(Api.deleteCampaign(campaignData.campaignId));
    const returnedCampaign = {
      campaignStatusIndex: campaignData.campaignStatusIndex,
      currentCampaignIndex: campaignData.currentCampaignIndex,
    }
    yield put({ type: actionTypes.DELETE_CAMPAIGN_ACTION.SUCCESS, currentCampaign: returnedCampaign });
    yield put({ type: actionTypes.DELETE_CAMPAIGN_ACTION.API_LOADING_STOP });
  } catch (error) {
    yield handleApiException(error); 
    yield put({ type: actionTypes.DELETE_CAMPAIGN_ACTION.API_LOADING_STOP });  
  }
}
export function* deleteCampaignSagas(): SagaType {
  yield takeLatest(actionTypes.DELETE_CAMPAIGN_ACTION.REQUEST, deleteCampaign);
}
export function* generateCampaignSagas(): SagaType {
  yield takeLatest(actionTypes.GENERATE_FILE_ACTION.REQUEST, generateCampaign);
}
export function* getAllCampaignSagas(): SagaType {
  yield takeLatest(actionTypes.GET_ALL_CAMPAIGN_ACTION.REQUEST, getAllCampaign);
}
export function* createCompaignSagas(): SagaType {
  yield takeLatest(actionTypes.CREATE_COMPAIGN_ACTION.REQUEST, createCompaign);
}
export function* uploadCompaignFileSagas(): SagaType {
  yield takeLatest(actionTypes.UPLOAD_FILE_ACTION.REQUEST, uploadFile);
}