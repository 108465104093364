import React from 'react';
import { Box } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import appStyle from '../../../../appStyle';
import translate from '../../../../services/Translation';

const styles = {
    container: {
        minHeight: 63,
        width: '100%',
        backgroundColor: appStyle.colors.pageBackground,
        boxShadow: ' 0 4px 5px -2px gray',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
     },
     menuButton: {
        outline: 'none',
        border: 'none',
        backgroundColor: 'transparent',
        cursor: 'pointer',
    },
    menuContainer: {
       display: 'flex',
       flexDirection: 'column',
       justifyContent: 'center',
    },
    arrowContainer: {
       display: 'flex',
       flexDirection: 'row',
       justifyContent: 'space-between',
       alignItems: 'center',
    },
}
type PropsType = {
    onPress: () => void,
    email: String,
};


class HeaderBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }
    props: PropsType;

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
      };
    
    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    onMenuClick() {
        this.setState({ anchorEl: null }, () => this.props.onPress());
    }
    render() {
        const { email } = this.props;
        return (
            <Box style={styles.container}>
            <div style={styles.menuContainer}>
                <Box style={styles.arrowContainer}>
                    <p
                        style={{
                            fontWeight: 'bold',
                            fontSize: appStyle.font.sizes.xs,
                        }}
                    >
                        {email}
                    </p>
                    <button
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={this.handleClick}
                        style={styles.menuButton}
                    >
                    <KeyboardArrowDown style={{ color: appStyle.colors.warmGrey }}/>
                    </button>
                </Box>
                <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                >
                    <MenuItem onClick={() => this.onMenuClick()}>{translate(('logout'))}</MenuItem>
                </Menu>
            </div>
            </Box>
        )
    }
}

export default HeaderBar; 